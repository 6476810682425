import "./Home.css";

import { Button, Layout, Row } from "antd";
import moment from "moment";
import preval from "preval.macro";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDatabase, goOffline, goOnline } from "firebase/database";
import PullToRefresh from "react-simple-pull-to-refresh";

import Navigation from "../Components/Navigation";
import { withDefaultState } from "../HOC/withDefaultState";
import { PATHS } from "../utils/const";
import { getCoveredInCard } from "../utils/cover";
import { logOut } from "../utils/firebaseDb";
import { get1ColumnRow } from "../utils/row";
import { PathNameType, Settings } from "../utils/types";
import AddRecord from "./AddRecord";
import AddGrid from "./AddGrid";
import Compare from "./Compare";
import ExercisesHelp from "./ExercisesHelp";
import Home from "./Home";
import Pairs from "./Pairs";
import Review from "./Review";
import SettingsComponent from "./SettingsComponent";
import Statistics from "./Statistics";
import Users from "./Users";
import { successMessage } from "../utils/message";

interface IStateProps {
  settings: Settings;
}

const { Content, Footer } = Layout;

function Main(props: IStateProps = {} as IStateProps) {
  const { settings } = props;
  const [path, setPath] = useState(PATHS.home);

  const location = useLocation();
  const pathName: PathNameType =
    location && (location.pathname as PathNameType);

  useEffect(() => {
    setPath(pathName);
  }, [pathName]);
  useEffect(() => {
    moment.updateLocale(settings.locale, {
      week: {
        dow: 1,
        doy: 1,
      },
    });
  }, [settings]);

  return (
    <Layout>
      <Navigation />
      <Layout style={{ minHeight: "-webkit-fill-available", height: "100%" }}>
        <PullToRefresh
          onRefresh={() => {
            return new Promise((res) => {
              const db = getDatabase();
              if(db){
                goOffline(db);
                goOnline(db);
              }
              setTimeout(() => {
                successMessage(!!db ? 'db online' : 'error');
                res(null);
              }, 100);
            });
          }}
          resistance={5}
        >
          <Content>
            <Row justify="center" align="middle" className="context-row">
              {path === PATHS.home && <Home />}
              {path === PATHS.add && <AddRecord />}
              {path === PATHS.addGrid && <AddGrid />}
              {path === PATHS.review && <Review />}
              {path === PATHS.compare && <Compare />}
              {path === PATHS.statistic && <Statistics />}
              {path === PATHS.pairs && <Pairs />}
              {path === PATHS.users && <Users />}
              {path === PATHS.exercises && <ExercisesHelp />}
              {path === PATHS.settings && <SettingsComponent />}
              {path === PATHS.about &&
                "Build: " +
                  preval`module.exports = new Date().toLocaleString();`}
              {path === PATHS.logout &&
                getCoveredInCard(
                  get1ColumnRow(
                    <Button onClick={logOut}>
                      Click for Logout and refresh page
                    </Button>
                  )
                )}
              <div style={{ width: "1px", height: "4em" }}></div>
            </Row>
          </Content>
        </PullToRefresh>
        <Footer
          style={{
            textAlign: "center",
            position: "fixed",
            left: 0,
            bottom: 0,
            width: "100%",
            padding: "1em",
          }}
        >
          <a href="https://znoj.cz">znoj.cz</a>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default withDefaultState(Main);
