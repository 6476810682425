import "./CategorySelect.css";

import { Row, Select } from "antd";
import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { Exercises } from "../../../utils/const";
import {
  ExerciseCategoryEnum,
  ExerciseCategoryName,
  IDefaultProps,
} from "../../../utils/types";
import {
  getExerciseArraySorted,
  getExerciseCategoryArraySorted,
  getExerciseId,
  getExerciseName,
} from "../../../utils/utils";

const { Option } = Select;

const getOptionRow = (content: string) => (
  <Row justify="start" align="middle" className="category-row">
    <div className="category-option-div">{content}</div>
  </Row>
);

function CategorySelect(props: IDefaultProps = {} as IDefaultProps) {
  const { category, exerciseType } = props.addRecordState;

  const handleSelectCategory = (value: number) => {
    if (
      Exercises[getExerciseName(exerciseType)].categories.indexOf(value) < 0
    ) {
      for (const exerciseName of getExerciseArraySorted()) {
        if (Exercises[exerciseName].categories.indexOf(value) >= 0) {
          props.updateAddRecordState({
            ...props.addRecordState,
            exerciseType: getExerciseId(exerciseName),
          });
        }
      }
    }
    props.updateAddRecordState({ ...props.addRecordState, category: value });
  };

  return (
    <Select
      defaultValue={category}
      value={category}
      size={"middle"}
      onChange={handleSelectCategory}
      className="category-select"
    >
      <Option value={-1} key={-1}>
        {getOptionRow("All")}
      </Option>
      {getExerciseCategoryArraySorted().map(
        (categoryName: ExerciseCategoryName) => (
          <Option
            value={ExerciseCategoryEnum[categoryName]}
            key={ExerciseCategoryEnum[categoryName]}
          >
            {getOptionRow(categoryName)}
          </Option>
        )
      )}
    </Select>
  );
}

export default withDefaultState(CategorySelect);
