export const actionTypes = {
  UPDATE_ADD_RECORD_STATE: "UPDATE_ADD_RECORD_STATE",
  UPDATE_AUTH_USER: "UPDATE_AUTH_USER",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  UPDATE_DATABASE: "UPDATE_DATABASE",
  UPDATE_USERS: "UPDATE_USERS",
  UPDATE_FRIENDS: "UPDATE_FRIENDS",
  ADD_FRIEND: "ADD_FRIEND",
};

export const getActionFunction = (action: string, data: any) => {
  return { type: action, data };
};
