import { DatePicker } from "antd";
import moment from "moment";
import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { IDefaultProps } from "../../../utils/types";

function DatePickerComponent(props: IDefaultProps = {} as IDefaultProps) {
  const { date } = props.addRecordState;
  const onDateChange = (momentDate: moment.Moment | null) => {
    momentDate &&
      props.updateAddRecordState({ ...props.addRecordState, date: momentDate });
  };

  const disabledDate = (current: moment.Moment) => {
    return current > moment().clone().endOf("day");
  };

  const getDatePicker = () => (
    <DatePicker
      size={"middle"}
      style={{ width: "80%", maxWidth: "150px" }}
      defaultValue={date}
      value={moment(date)}
      allowClear={false}
      disabledDate={disabledDate}
      onChange={onDateChange}
    />
  );

  return getDatePicker();
}

export default withDefaultState(DatePickerComponent);
