import React from "react";

import { get2diffColumnsRow } from "../../../utils/row";
import DatePickerComponent from "./DatePickerComponent";

function DatePickerRow() {
  const title: string = "Date";
  const inputComponent: React.ReactElement = <DatePickerComponent />;
  return <>{get2diffColumnsRow(title, inputComponent)}</>;
}

export default DatePickerRow;
