import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

// compat SDK needed for FirebaseUI
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "../firebaseInit";

export function SignInForm() {
  // convert the v9 auth instance into a v8 auth instance
  const v8AppInstance = firebase.initializeApp(firebaseConfig);
  const v8AuthInstance = v8AppInstance.auth();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/",
    // We will display Google as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  };

  return (
    <StyledFirebaseAuth firebaseAuth={v8AuthInstance} uiConfig={uiConfig} />
  );
}
