import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { Exercises } from "../../../utils/const";
import { ExerciseName, IDefaultProps } from "../../../utils/types";
import {
  getExerciseName,
  haveToNormalizeTypeNumber,
} from "../../../utils/utils";
import NumericInput from "../../NumericInput";

function CountInput(props: IDefaultProps = {} as IDefaultProps) {
  const { count, exerciseType } = props.addRecordState;
  const onChangeRealCount = (value: number) => {
    if (haveToNormalizeTypeNumber(exerciseType)) {
      const newPoints: number =
        value *
        Exercises[getExerciseName(exerciseType) as ExerciseName].coefficient;
      props.updateAddRecordState({
        ...props.addRecordState,
        count: value,
        points: newPoints,
      });
    }
  };

  return (
    <NumericInput
      value={count}
      onChange={onChangeRealCount}
      withDecimal={true}
    />
  );
}

export default withDefaultState(CountInput);
