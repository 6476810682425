import React from "react";

import { get2diffColumnsRow } from "../../../utils/row";
import TimePickerComponent from "./TimePickerComponent";

function TimePickerRow() {
  const title: string = "Time";
  const inputComponent: React.ReactElement = <TimePickerComponent />;
  return <>{get2diffColumnsRow(title, inputComponent)}</>;
}

export default TimePickerRow;
