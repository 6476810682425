import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };
export const firebaseConfig = {
  apiKey: "AIzaSyCZAAyY7D822SM3FyLNGpvbf40mASoHzIA",
  authDomain: "workout-85b5b.firebaseapp.com",
  databaseURL: "https://workout-85b5b.firebaseio.com",
  projectId: "workout-85b5b",
  storageBucket: "workout-85b5b.appspot.com",
  messagingSenderId: "196234320029",
  appId: "1:196234320029:web:2815d74eac22da10fe5c74",
};

export const firebaseInit = () => initializeApp(firebaseConfig)
