import "./Navigation.css";

import { Card, Modal, Row, Table } from "antd";
import React from "react";
import { connect } from "react-redux";

import { COLUMNS } from "../utils/columns";
import { deleteRecord } from "../utils/firebaseDb";
import { DatabaseRecord, IDefaultState, Settings } from "../utils/types";

interface IStateProps {
  settings: Settings;
}

interface IOwnProps {
  showModal: boolean;
  itemToDelete: DatabaseRecord | false;
  setShowModal: () => void;
  setLoading: () => void;
}

interface IProps extends IStateProps, IOwnProps {}

function DeleteModal(props: IProps = {} as IProps) {
  const { itemToDelete, setShowModal, setLoading, settings, showModal } = props;

  const handleOk = () => {
    setShowModal();
    itemToDelete &&
      typeof itemToDelete.id === "number" &&
      deleteRecord(itemToDelete.id, () => setLoading());
  };

  const handleCancel = () => {
    setLoading();
  };

  return (
    <Modal
      title="Delete this row?"
      open={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okType="danger"
      okText="Yes"
      cancelText="No"
    >
      <Table
        size={"small"}
        rowKey={(record: DatabaseRecord) => record.date + record.id}
        dataSource={itemToDelete ? [itemToDelete] : []}
        // @ts-ignore
        columns={COLUMNS(settings.locale)}
        pagination={false}
      />
      {
        <Row justify="center" style={{ width: "100%", paddingTop: "16px" }}>
          <Card hoverable={true}>This can not be undone!</Card>
        </Row>
      }
    </Modal>
  );
}

const mapStateToProps = (
  state: IDefaultState,
  ownProps: IOwnProps
): IProps => ({
  ...state,
  ...ownProps,
});

export default connect(mapStateToProps, null)(DeleteModal);
