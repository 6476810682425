import React from "react";

import { get1ColumnRow } from "../../../utils/row";
import SliderComponent from "./SliderComponent";
import { ISliderProps } from "./type";

function SliderRow(props: ISliderProps = {} as ISliderProps) {
  const inputComponent: React.ReactElement = (
    <SliderComponent isDec={props.isDec} />
  );
  return <>{get1ColumnRow(inputComponent)}</>;
}

export default SliderRow;
