import { UserInfo } from "firebase/auth";
import moment from "moment";
// import firebase from "firebase/compat";

export type AuthUser = UserInfo | null;

export type IPairs = Map<string, DatabaseRecord[]>;

export interface Settings {
  goal: number;
  locale: Locale;
  nick: string;
  lastFriendId?: string;
}

export enum ExerciseCategoryEnum {
  "Sports" = 0,
  "Abs" = 1,
  "Arms" = 2,
  "Shoulders" = 3,
  "Back" = 4,
  "Legs" = 5,
  "Buttocks" = 6,
  "Hips" = 7,
  "Chest" = 8,
}

export declare type ExerciseName =
  | "PullUps"
  | "PushUps"
  | "Squats"
  | "SitUps"
  | "Burpees"
  | "HipThrust"
  | "Dumbbells"
  | "Dips"
  | "Walk"
  | "Run"
  | "Bike"
  | "Swim"
  | "RollerSkates"
  | "SMSystem"
  | "Plank"
  | "Gym"
  | "Fitness"
  | "Yoga";

export declare type ExerciseCategoryName =
  | "Sports"
  | "Abs"
  | "Arms"
  | "Shoulders"
  | "Back"
  | "Legs"
  | "Buttocks"
  | "Hips"
  | "Chest";

export declare type Unit = "km" | "m" | "min" | "sec" | "";
export type ExerciseDetail = {
  name: string;
  id: number;
  coefficient: number;
  unit: Unit;
  categories: ExerciseCategoryEnum[];
};

export type ExerciseType = {
  [key: string]: ExerciseDetail;
};

export interface ChartData {
  date: number;
  dateString?: string;
  PullUps: number;
  PushUps: number;
  Squats: number;
  SitUps: number;
  Burpees: number;
  HipThrust: number;
  Dumbbells: number;
  Dips: number;
  Kickback: number;
  Lunges: number;
  Walk: number;
  Run: number;
  Bike: number;
  Swim: number;
  RollerSkates: number;
  SMSystem: number;
  Plank: number;
  Gym: number;
  Fitness: number;
  Yoga: number;
}

export declare type PathNameType =
  | "/"
  | "/add"
  | "/addGrid"
  | "/review"
  | "/compare"
  | "/exercises"
  | "/statistic"
  | "/users"
  | "/settings"
  | "/about"
  | "/logout";

export declare type PickerType = "date" | "week" | "month" | "year";
export declare type NotificationType = "success" | "info" | "error" | "warning";

export interface SummaryData {
  type: string;
  count: number;
  realCount?: number;
  avg: string;
  min: string;
  max: string;
  friend?: number;
  nick?: string;
}

export interface MenuItem {
  path: PathNameType;
  title: string;
}

export interface DatabaseRecordNoId {
  count: number;
  realCount: number;
  date: number;
  type: number;
}

export interface DatabaseRecord extends DatabaseRecordNoId {
  id: number;
}

export interface PairTableRow extends DatabaseRecord {
  records: number;
}

export interface User {
  id: string;
  nick: string;
}

export interface UserFriendRelation {
  id: string;
  nick: string;
  allowAccess: boolean;
  haveAccess: boolean;
}

export interface FriendData {
  database: DatabaseRecord[];
  haveAccess: boolean;
}
export interface Friend extends User, FriendData { }

export interface IAddRecordStateProps {
  date: moment.Moment;
  category: number;
  exerciseType: number;
  count: number;
  points: number;
  loading: boolean;
  showModal: boolean;
  itemToDelete: DatabaseRecord | false;
  tabChoice: number;
}

export interface IDefaultState {
  addRecordState: IAddRecordStateProps;
  authUser: AuthUser;
  database: DatabaseRecord[];
  friends: Friend[];
  settings: Settings;
  users: User[];
}
export interface WithDatePickerProps {
  picker: PickerType;
  allDates: boolean;
  date: moment.Moment;
  startPeriod: number;
  endPeriod: number;
  settings: Settings;
  database: DatabaseRecord[];
  friends: Friend[];
}
export interface AddRecordDispatchProps {
  updateAddRecordState: (addRecordState: IAddRecordStateProps) => void;
}
export interface IDefaultDispatchProps extends AddRecordDispatchProps {
  updateAuthUser: (authUser: AuthUser) => void;
  updateSettings: (settings: Settings) => void;
  updateDatabase: (database: DatabaseRecord[]) => void;
  updateUsers: (users: User[]) => void;
  updateFriends: (friends: Friend[]) => void;
  addFriend: (friend: Friend) => void;
}
export interface IDefaultProps extends IDefaultState, IDefaultDispatchProps { }

export interface INumericInput {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  withDecimal?: boolean;
}

export declare type Locale =
  | "ar-SA"
  | "bn-BD"
  | "bn-IN"
  | "cs-CZ"
  | "da-DK"
  | "de-AT"
  | "de-CH"
  | "de-DE"
  | "el-GR"
  | "en-AU"
  | "en-CA"
  | "en-GB"
  | "en-IE"
  | "en-IN"
  | "en-NZ"
  | "en-US"
  | "en-ZA"
  | "es-AR"
  | "es-CL"
  | "es-CO"
  | "es-ES"
  | "es-MX"
  | "es-US"
  | "fi-FI"
  | "fr-BE"
  | "fr-CA"
  | "fr-CH"
  | "fr-FR"
  | "he-IL"
  | "hi-IN"
  | "hu-HU"
  | "id-ID"
  | "it-CH"
  | "it-IT"
  | "jp-JP"
  | "ko-KR"
  | "nl-BE"
  | "nl-NL"
  | "no-NO"
  | "pl-PL"
  | "pt-BR"
  | "pt-PT"
  | "ro-RO"
  | "ru-RU"
  | "sk-SK"
  | "sv-SE"
  | "ta-IN"
  | "ta-LK"
  | "th-TH"
  | "tr-TR"
  | "zh-CN"
  | "zh-HK"
  | "zh-TW";
