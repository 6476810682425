import { Input } from "antd";
import React, { useEffect, useState } from "react";

import { INumericInput } from "../utils/types";

function NumericInput(props: INumericInput = {} as INumericInput) {
  const { value, withDecimal } = props;
  const [localValue, setLocalValue] = useState(value.toString());

  useEffect(() => {
    setLocalValue(props.value.toString());
  }, [props.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let reg = /^\d*$/;
    if (withDecimal) {
      reg = /^\d*(\.\d?)?$/;
    }
    if (value === "") {
      setLocalValue(value);
    } else if (!isNaN(+value) && reg.test(value)) {
      setLocalValue(value);
      if (value.slice(-1) !== ".") {
        props.onChange(+value);
      }
    }
  };

  // '.' at the end, trim 0s from begin
  const onBlur = () => {
    let valueTemp = localValue;
    if (localValue.charAt(localValue.length - 1) === ".") {
      valueTemp = localValue.slice(0, -1);
    }
    const fixedValue: string = valueTemp.replace(/0*(\d+)/, "$1");
    setLocalValue(fixedValue);
    props.onChange(+fixedValue);
  };

  return (
    <Input
      size={"middle"}
      style={{ width: "80%", maxWidth: "150px" }}
      value={localValue}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={6}
      disabled={props.disabled}
    />
  );
}

export default NumericInput;
