import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";

import { Exercises } from "../../../utils/const";
import { addNewRecord } from "../../../utils/firebaseDb";
import { processNotification } from "../../../utils/message";
import { get2sameColumnsRow, get3ColumnsRows } from "../../../utils/row";
import {
  DatabaseRecord,
  ExerciseDetail,
  IAddRecordStateProps,
  IPairs,
  Settings,
} from "../../../utils/types";
import {
  getCountChosenDays,
  getExerciseName,
  getPairs,
  getSortedPairs,
} from "../../../utils/utils";

interface Iprops {
  database: DatabaseRecord[];
  addRecordState: IAddRecordStateProps;
  settings: Settings;
  updateAddRecordState: (addRecordState: IAddRecordStateProps) => void;
  mostRecent?: boolean;
  initialCount?: number;
}
function PairsButtons(props: Iprops = {} as Iprops) {
  const { loading, date } = props.addRecordState;
  const {
    database,
    addRecordState,
    settings,
    updateAddRecordState,
    mostRecent,
    initialCount = 9
  } = props;

  const [pairs, setPairs] = useState(
    new Map<string, DatabaseRecord[]>() as IPairs
  );
  const [countOfVisible, setCountOfVisible] = useState(initialCount);
  const [countChosenDays, setCountChosenDays] = useState(0); // setup by getCountChosenDays

  useEffect(() => {
    if (database) {
      if (mostRecent) {
        setPairs(getPairs(database));
      } else {
        // mostCommon
        setPairs(getSortedPairs(database));
      }
      setCountChosenDays(getCountChosenDays(database, date));
    }
  }, [database, date, mostRecent]);

  const done = (count: number, countChosenDays: number) => () => {
    updateAddRecordState({ ...addRecordState, loading: false });
    processNotification(count, countChosenDays, settings.goal);
  };

  const exercisesArrays: React.ReactElement[][] = [];
  // [string, DatabaseRecord[]]
  [...pairs.entries()].forEach((pair: any, index: number) => {
    if (index < countOfVisible && !!pair[1]) {
      const firstDatabaseRecord: DatabaseRecord = pair[1][0]; // first record of the pair array
      const currentExercise: ExerciseDetail =
        Exercises[getExerciseName(firstDatabaseRecord.type)];

      const button = (
        <Card
          bordered={true}
          hoverable={!loading}
          size={'small'}
          key={index}
          onClick={() => {
            updateAddRecordState({ ...addRecordState, loading: true });
            addNewRecord(
              database,
              {
                count: firstDatabaseRecord.count,
                realCount: firstDatabaseRecord.realCount
                  ? firstDatabaseRecord.realCount
                  : firstDatabaseRecord.count,
                date: date.valueOf(),
                type: firstDatabaseRecord.type,
              },
              done(firstDatabaseRecord.count, countChosenDays)
            );
          }}
          bodyStyle={{padding:  "2px, 4px"}}
        >
          {get2sameColumnsRow(
            <Col span={24}>
              <Row justify="start" align="middle">
                <h3>{(currentExercise.coefficient !== 1
                  ? firstDatabaseRecord.realCount
                  : firstDatabaseRecord.count)}
                </h3>
              </Row>
              <Row justify="start" align="middle">
                {(currentExercise.coefficient !== 1
                  ? currentExercise.unit
                  : null)}
              </Row>
            </Col>
            , <Col span={24}>
              <Row justify="center" align="top">
                <img
                  src={`./icons/${currentExercise.name}.svg`}
                  alt=""
                  className="exercise-img exercise-img-full"
                />
              </Row>
              <Row justify="center" align="bottom">
                {currentExercise.name}
              </Row>
            </Col>, 'same-column-row-no-padding')}
        </Card>
      );
      const exercisesArrayIndex = Math.floor(index / 3);
      if (!exercisesArrays[exercisesArrayIndex]) {
        exercisesArrays[exercisesArrayIndex] = [button];
      } else {
        exercisesArrays[exercisesArrayIndex].push(button);
      }
    }
  });

  return (
    <>
      {get2sameColumnsRow(
        <Button
          type="link"
          disabled={countOfVisible >= pairs.size}
          onClick={() => {
            setCountOfVisible(countOfVisible + 3);
          }}
        >
          add pairs
        </Button>,
        <Button
          type="link"
          disabled={countOfVisible === initialCount}
          onClick={() => {
            setCountOfVisible(initialCount);
          }}
        >
          remove added pairs
        </Button>,
        "two-column-row-no-padding"
      )}
      {exercisesArrays.map((exercises: React.ReactElement[]) =>
        get3ColumnsRows(exercises)
      )}
    </>
  );
}

export default PairsButtons;
