import { message, notification } from "antd";

import { NotificationType } from "./types";

const openMessage = (
  type: NotificationType,
  messageString: string,
  timeout: number
) => {
  message[type](messageString, timeout);
};

export const errorMessage = (text: string) => {
  openMessage("error", text, 5);
};

export const successMessage = (text: string) => {
  openMessage("success", text, 1);
};

const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  message: string
) => {
  notification[type]({
    message: title,
    description: message,
    placement: "bottomRight",
    duration: 2,
  });
};

export const infoNotification = (title: string, message: string) => {
  openNotificationWithIcon("info", title, message);
};

export const successNotification = (title: string, message: string) => {
  openNotificationWithIcon("success", title, message);
};

export const processNotification = (
  count: number,
  countChosenDays: number,
  goal: number
): void => {
  count + countChosenDays >= goal
    ? successNotification("Today review", "Goal done.")
    : infoNotification(
        "Today review",
        "TODO " + (goal - count - countChosenDays)
      );
};
