import { Spin } from "antd";
import React from "react";

import { LoadingOutlined } from "@ant-design/icons";

import { getCoveredInCard } from "../utils/cover";
import { get1ColumnRow } from "../utils/row";

interface IState {
  customComponent: React.ReactElement;
}
export default function LoadingComponent(
  props: { customComponent?: React.ReactElement } = {} as IState
) {
  return (
    <>
      {getCoveredInCard(
        get1ColumnRow(
          props.customComponent || <Spin indicator={<LoadingOutlined />} />
        )
      )}
    </>
  );
}
