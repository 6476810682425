import { TimePicker } from "antd";
import moment from "moment";
import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { IDefaultProps } from "../../../utils/types";

function TimePickerComponent(props: IDefaultProps = {} as IDefaultProps) {
  const { date } = props.addRecordState;
  const onTimeChange = (momentTime: moment.Moment | null) => {
    const newDate = moment(date);
    momentTime &&
      newDate.set({
        hour: momentTime.get("hour"),
        minute: momentTime.get("minute"),
      });
    momentTime &&
      props.updateAddRecordState({ ...props.addRecordState, date: newDate });
  };

  return (
    <TimePicker
      size={"middle"}
      defaultValue={moment(date, "HH:mm")}
      allowClear={false}
      format={"HH:mm"}
      style={{ width: "80%", maxWidth: "150px" }}
      onChange={onTimeChange}
    />
  );
}

export default withDefaultState(TimePickerComponent);
