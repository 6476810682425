import { Button, DatePicker, Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import LoadingComponent from "../Components/LoadingComponent";
import { PICKER_TYPES } from "../utils/const";
import { getCoveredInCard } from "../utils/cover";
import { get2sameColumnsRow, get3ColumnsRows } from "../utils/row";
import {
  DatabaseRecord,
  Friend,
  PickerType,
  Settings,
  WithDatePickerProps,
} from "../utils/types";
import { sortedDatabase } from "../utils/utils";

const { Option } = Select;

interface IStateProps {
  database: DatabaseRecord[];
  friends: Friend[];
  settings: Settings;
}

export const withDatePicker = (
  WrappedComponent: React.ComponentType<WithDatePickerProps>
) => {
  const mapStateToProps = (state: IStateProps) => ({ ...state });

  return connect(
    mapStateToProps,
    null
  )(
    class WithDatePicker extends React.Component<
      {
        settings: Settings;
        database: DatabaseRecord[];
        friends: Friend[];
      },
      WithDatePickerProps
    > {
      constructor(props: {
        settings: Settings;
        database: DatabaseRecord[];
        friends: Friend[];
      }) {
        super(props);
        const picker = PICKER_TYPES[1];
        const date = moment();
        const allDates = false;
        this.state = {
          ...props,
          picker,
          allDates,
          date,
          startPeriod: this.getPeriod(true, date, picker, allDates),
          endPeriod: this.getPeriod(false, date, picker, allDates),
        };
      }

      getPeriod = (
        start: boolean,
        date: moment.Moment,
        picker: PickerType,
        allDates: boolean = false
      ): number => {
        const period = moment(date);
        const ofValue = picker === "date" ? "day" : allDates ? "year" : picker;

        const firstDate = sortedDatabase(this.props.database)[0].date;
        const firstDateValue = moment(firstDate).startOf("date").valueOf();
        let givenStartOfValue = period.clone().startOf(ofValue).valueOf();
        givenStartOfValue =
          givenStartOfValue < firstDateValue
            ? firstDateValue
            : givenStartOfValue;

        const lastDateValue = moment().clone().endOf("date").valueOf();
        const givenEndOfValue = period.clone().endOf(ofValue).valueOf();

        if (start) {
          if (allDates) {
            return firstDateValue;
          }
          return givenStartOfValue < firstDateValue
            ? firstDateValue
            : givenStartOfValue;
        }
        if (givenEndOfValue < givenStartOfValue) {
          return givenStartOfValue;
        }
        return givenEndOfValue > lastDateValue
          ? lastDateValue
          : givenEndOfValue;
      };

      onDateChange = (date: moment.Moment | null) => {
        if (date) {
          this.setState((prevState) => {
            return {
              ...prevState,
              date,
              startPeriod: this.getPeriod(true, date, this.state.picker),
              endPeriod: this.getPeriod(false, date, this.state.picker),
            };
          });
        }
      };

      handleSelect = (pickerParam: PickerType | "all") => {
        const picker = pickerParam === "all" ? "year" : pickerParam;
        this.setState((prevState) => {
          return {
            ...prevState,
            picker,
            allDates: pickerParam === "all",
            date: moment(),
            startPeriod: this.getPeriod(
              true,
              moment(),
              picker,
              pickerParam === "all"
            ),
            endPeriod: this.getPeriod(
              false,
              moment(),
              picker,
              pickerParam === "all"
            ),
          };
        });
      };

      disabledDate = (current: moment.Moment) => {
        return current > moment().clone().endOf("day");
      };

      render() {
        const { allDates, date, picker } = this.state;
        const firstDate = moment(
          sortedDatabase(this.props.database)[0]
        ).startOf(picker === "date" ? "day" : picker);
        const lastDate = moment().endOf(picker === "date" ? "day" : picker);
        let prevDate = moment(date).subtract(
          picker === "date"
            ? 1
            : moment(date)
                .clone()
                .endOf(picker)
                .diff(moment(date).clone().startOf(picker), "days"),
          "days"
        );
        if (prevDate.valueOf() < firstDate.valueOf()) {
          prevDate = firstDate;
        }
        let nextDate = moment(date).add(
          picker === "date"
            ? 1
            : moment(date)
                .clone()
                .endOf(picker)
                .diff(moment(date).clone().startOf(picker), "days"),
          "days"
        );
        if (nextDate.valueOf() > lastDate.valueOf()) {
          nextDate = lastDate;
        }

        return this.props.settings ? (
          <React.Fragment key="0">
            {getCoveredInCard(
              <React.Fragment key="1">
                {get2sameColumnsRow(
                  <DatePicker
                    size={"middle"}
                    defaultValue={date}
                    disabledDate={this.disabledDate}
                    picker={picker}
                    allowClear={false}
                    onChange={this.onDateChange}
                    showToday={true}
                    disabled={allDates}
                    key="DatePicker"
                  />,
                  <Select
                    value={allDates ? "all" : picker}
                    size={"middle"}
                    onChange={this.handleSelect}
                  >
                    {PICKER_TYPES.map(
                      (pickerType: PickerType, index: number) => (
                        <Option value={pickerType} key={index}>
                          {(pickerType === "date" && "day") || pickerType}
                        </Option>
                      )
                    )}
                    <Option value={"all"} key={-1}>
                      all
                    </Option>
                  </Select>,
                  "same-column-row-no-side-padding"
                )}
                {get3ColumnsRows([
                  <React.Fragment key="2">
                    {moment(this.state.startPeriod)
                      .toDate()
                      .toLocaleDateString(this.props.settings.locale)}
                  </React.Fragment>,
                  <React.Fragment key="3">
                    {moment(this.state.endPeriod)
                      .toDate()
                      .toLocaleDateString(this.props.settings.locale)}
                  </React.Fragment>,
                  <React.Fragment key="4">
                    {moment(this.state.endPeriod).diff(
                      moment(this.state.startPeriod),
                      "days"
                    ) + 1}{" "}
                    days
                  </React.Fragment>,
                ])}
                {!allDates &&
                  get3ColumnsRows([
                    <LeftOutlined
                      style={{ fontSize: "24px" }}
                      key="prev"
                      onClick={() => this.onDateChange(prevDate)}
                    />,
                    <Button
                      type="link"
                      key="now"
                      onClick={() => this.onDateChange(moment())}
                      disabled={
                        moment().clone().startOf("day") <= date &&
                        date <= moment().clone().endOf("day")
                      }
                    >
                      now
                    </Button>,
                    <RightOutlined
                      key="post"
                      style={{ fontSize: "24px" }}
                      onClick={() => this.onDateChange(nextDate)}
                      hidden={date >= moment().clone().startOf("day")}
                    />,
                  ])}
              </React.Fragment>
            )}
            <WrappedComponent {...this.state} {...this.props} />
          </React.Fragment>
        ) : (
          <LoadingComponent />
        );
      }
    }
  );
};
