import moment, { Moment } from "moment";

import { Exercises } from "./const";
import {
  DatabaseRecord,
  ExerciseCategoryEnum,
  ExerciseCategoryName,
  ExerciseDetail,
  ExerciseName,
  IPairs,
  PickerType,
  SummaryData,
} from "./types";

// return ['PullUps', 'PushUps', 'Squats', 'SitUps', 'Burpees', 'Lunges', 'Dumbbells', 'Dips', 'HipThrust', 'Kickback', 'Walk', 'Run', 'Bike', 'Gym', 'Yoga']
export const getExerciseArray = (): ExerciseName[] =>
  Object.keys(Exercises) as ExerciseName[];

export const getExerciseArraySorted = (): ExerciseName[] =>
  getExerciseArray().sort((e1: ExerciseName, e2: ExerciseName) =>
    e1 < e2 ? -1 : 1
  );

export const getExerciseCategoryArray = (): ExerciseCategoryName[] =>
  Object.keys(ExerciseCategoryEnum).filter((key) =>
    isNaN(+key)
  ) as ExerciseCategoryName[];

export const getExerciseCategoryArraySorted = (): ExerciseCategoryName[] =>
  getExerciseCategoryArray().sort(
    (e1: ExerciseCategoryName, e2: ExerciseCategoryName) => (e1 < e2 ? -1 : 1)
  );

export const getExercise = (index: number): ExerciseDetail => {
  const filteredArray = getExerciseArray().filter(
    (exerciseName: string) => Exercises[exerciseName].id === index
  );
  if (filteredArray.length === 1) {
    return Exercises[filteredArray[0] as ExerciseName];
  }
  throw new Error(`getExercise: Wrong exercise index: ${index}`);
};

// return name of the type given by index (for 0 get 'PullUps', for 1 get 'PushUps', ...)
export const getExerciseName = (index: number): ExerciseName => {
  const filteredArray = getExerciseArray().filter(
    (exerciseName: string) => Exercises[exerciseName].id === index
  );
  if (filteredArray.length === 1) {
    return filteredArray[0];
  }
  throw new Error(`getExerciseName: Wrong exercise index: ${index}`);
};

export const getExerciseId = (name: ExerciseName): number => {
  return Exercises[name].id;
};

export const haveToNormalizeTypeNumber = (key: number): boolean => {
  if (key === -1 || !(typeof key === "number")) {
    return false;
  }
  return getExercise(key).coefficient !== 1;
};

export const getGoal = (
  picker: PickerType,
  goalFromSettings: number,
  date: Moment
) => {
  return picker === "date"
    ? goalFromSettings
    : picker === "week"
      ? goalFromSettings * 7
      : picker === "month"
        ? goalFromSettings * date.daysInMonth()
        : goalFromSettings * 365;
};

// [string, DatabaseRecord[]]
export const sortByType = (a: any, b: any): number => {
  return +a[1][0].type - +b[1][0].type;
};
// [string, DatabaseRecord[]]
export const sortByCount = (a: any, b: any): number => {
  return +b[1].length - +a[1].length;
};

export const sortedDatabase = (database: DatabaseRecord[]): DatabaseRecord[] =>
  [...database].sort((a: DatabaseRecord, b: DatabaseRecord) =>
    +a.date === +b.date ? +a.id - +b.id : +a.date - +b.date
  );
// a, b are [string, DatabaseRecord[]]
export const getPairs = (database: DatabaseRecord[]): IPairs => {
  const pairMap: IPairs = new Map<string, DatabaseRecord[]>();

  sortedDatabase(database)
    .reverse()
    .forEach((exercise: DatabaseRecord) => {
      const currentExercise: ExerciseDetail =
        Exercises[getExerciseName(exercise.type)];
      const pairKey =
        exercise.type +
        "_" +
        (currentExercise.coefficient !== 1
          ? exercise.realCount + " " + currentExercise.unit
          : exercise.count);
      const mapValue: DatabaseRecord[] = pairMap.get(pairKey) || [];
      const newValue: DatabaseRecord[] = mapValue.concat(exercise);
      pairMap.set(pairKey, newValue);
    });

  return pairMap;
};

// a, b are [string, DatabaseRecord[]]
export const getSortedPairs = (
  database: DatabaseRecord[],
  sortAlg: (a: any, b: any) => number = sortByCount
): IPairs => {
  const pairMap: IPairs = new Map<string, DatabaseRecord[]>();

  database.forEach((exercise: DatabaseRecord) => {
    const currentExercise: ExerciseDetail =
      Exercises[getExerciseName(exercise.type)];
    const pairKey =
      exercise.type +
      "_" +
      (currentExercise.coefficient !== 1
        ? exercise.realCount + " " + currentExercise.unit
        : exercise.count);
    const mapValue: DatabaseRecord[] = pairMap.get(pairKey) || [];
    const newValue: DatabaseRecord[] = mapValue.concat(exercise);
    pairMap.set(pairKey, newValue);
  });

  const sortedPairMap: IPairs = new Map<string, DatabaseRecord[]>(
    [...pairMap.entries()].sort(sortAlg)
  );

  return sortedPairMap;
};

export const getCountChosenDays = (
  database: DatabaseRecord[],
  date: Moment
): number => {
  let countChosenDays = 0;

  const startPeriod: number = moment(date).clone().startOf("date").valueOf();
  const endPeriod: number = moment(date).clone().endOf("date").valueOf();

  database
    .filter(
      (record: DatabaseRecord) =>
        record.date >= startPeriod && record.date <= endPeriod
    )
    .forEach((record) => (countChosenDays += record.count));

  return countChosenDays;
};

export const mainSortFunction = (row1: SummaryData, row2: SummaryData) => {
  if (row1.type === row2.type) {
    return row2.count - row1.count;
  }
  if (row1.type === "Finished") {
    return -1;
  }
  if (row2.type === "Finished") {
    return +1;
  }
  return 0;
}