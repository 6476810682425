import { Slider } from "antd";
import React from "react";
import { connect } from "react-redux";

import { actionTypes, getActionFunction } from "../../../actions";
import { Exercises } from "../../../utils/const";
import { ExerciseName, IAddRecordStateProps } from "../../../utils/types";
import {
  getExerciseName,
  haveToNormalizeTypeNumber,
} from "../../../utils/utils";
import { ISliderProps } from "./type";

interface IStateProps {
  addRecordState: IAddRecordStateProps;
}
interface IDispatchProps {
  updateAddRecordState: (addRecordState: IAddRecordStateProps) => void;
}
interface IProps extends ISliderProps, IStateProps, IDispatchProps {}

function DatePickerComponent(props: IProps = { isDec: true } as IProps) {
  const { count, exerciseType, points } = props.addRecordState;
  const { isDec } = props;

  const onChangeCountDec = (value: number | undefined) => {
    if (haveToNormalizeTypeNumber(exerciseType)) {
      const newCount = (value as number) * 10 + (count % 10);
      const newPoints =
        newCount *
        Exercises[getExerciseName(exerciseType) as ExerciseName].coefficient;
      if (newCount !== count && count <= 109) {
        props.updateAddRecordState({
          ...props.addRecordState,
          count: newCount,
          points: newPoints,
        });
      }
    } else {
      const fullValue = (value as number) * 10 + (points % 10);
      if (fullValue !== count && count <= 109) {
        props.updateAddRecordState({
          ...props.addRecordState,
          count: fullValue,
          points: fullValue,
        });
      }
    }
  };
  const onChangeCountRest = (value: number | undefined) => {
    if (haveToNormalizeTypeNumber(exerciseType)) {
      const newCount = Math.floor(count / 10) * 10 + (value as number);
      const newPoints =
        newCount *
        Exercises[getExerciseName(exerciseType) as ExerciseName].coefficient;
      if (newCount !== count) {
        props.updateAddRecordState({
          ...props.addRecordState,
          count: newCount,
          points: newPoints,
        });
      }
    } else {
      const fullValue = Math.floor(points / 10) * 10 + (value as number);
      if (fullValue !== count) {
        props.updateAddRecordState({
          ...props.addRecordState,
          count: fullValue,
          points: fullValue,
        });
      }
    }
  };

  const getDec = () => {
    let editableCount = points;
    if (haveToNormalizeTypeNumber(exerciseType)) {
      editableCount = count;
    }
    return typeof editableCount === "number"
      ? Math.floor(editableCount / 10)
      : 0;
  };
  const getRest = () => {
    let editableCount = points;
    if (haveToNormalizeTypeNumber(exerciseType)) {
      editableCount = count;
    }
    return typeof editableCount === "number" ? editableCount % 10 : 0;
  };

  return (
    <Slider
      dots={true}
      min={0}
      max={isDec ? 10 : 9}
      step={1}
      onChange={isDec ? onChangeCountDec : onChangeCountRest}
      value={isDec ? getDec() : getRest()}
      style={{ width: "100%" }}
    />
  );
}

const mapStateToProps = (state: IProps, ownProps: ISliderProps) => ({
  ...state,
  ...ownProps,
});
const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
  updateAddRecordState: (addRecordState: IAddRecordStateProps) =>
    dispatch(
      getActionFunction(actionTypes.UPDATE_ADD_RECORD_STATE, addRecordState)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePickerComponent);
