import { DataSnapshot, getDatabase, onValue, ref, remove, set } from "firebase/database";

import { errorMessage, successMessage } from "./message";
import {
  AuthUser,
  DatabaseRecord,
  DatabaseRecordNoId,
  Friend,
  Settings,
  User,
} from "./types";
import { getAuth } from "firebase/auth";

let authUser: AuthUser = null;

export const onComplete = (
  name: string,
  onDone: () => void,
  error: Error | null
) => {
  if (error) {
    // The write failed...
    errorMessage(`${name} - error: ${error}`);
  } else {
    // Data saved successfully!
    successMessage(`${name} - success`);
  }
  onDone();
};

export const addNewRecord = async (
  database: DatabaseRecord[],
  newRecord: DatabaseRecordNoId,
  onDone: () => void
) => {
  if (authUser) {
    const id: number =
      database && database.length > 0
        ? Math.max(...database.map((rec: DatabaseRecord) => rec.id)) + 1
        : 0;

    set(ref(getDatabase(), `${authUser.uid}/records/` + id), { ...newRecord, id })
      .then(() => {
        onComplete("Add new record", onDone, null);
      })
      .catch((error) => {
        onComplete(`Add new failed: ${error}`, onDone, error);
      });
  } else {
    onComplete(`Add new failed - no authUser`, onDone, null);
  }
};

export const deleteRecord = (id: number, onDone: () => void) => {
  if (authUser) {
    remove(ref(getDatabase(), `${authUser.uid}/records/` + id))
      .then(() => {
        onComplete("Delete record", onDone, null);
      })
      .catch((error) => {
        onComplete(`Delete record failed: ${error}`, onDone, error);
      });
  } else {
    onComplete(`Delete record failed - no authUser`, onDone, null);
  }
};

export const updateSettings = (newSettigs: Settings, onDone: () => void) => {
  if (authUser) {
    set(ref(getDatabase(), `${authUser.uid}/settings/`), newSettigs)
      .then(() => {
        onComplete("Update settings", onDone, null);
      })
      .catch((error) => {
        onComplete(`Update settings failed: ${error}`, onDone, error);
      });
  } else {
    onComplete(`Update settings failed - no authUser`, onDone, null);
  }
};

export const addFriend = (friend: User, onDone: () => void) => {
  if (authUser) {
    set(ref(getDatabase(), `${authUser.uid}/friends/${friend.id}`), friend.nick)
      .then(() => {
        onComplete("Add friend", onDone, null);
      })
      .catch((error) => {
        onComplete(`Add friend failed: ${error}`, onDone, error);
      });
  } else {
    onComplete(`Add friend failed - no authUser`, onDone, null);
  }
};

export const getFriendData = (
  friend: Friend | User,
  onDone: (friend: Friend) => void
) => {
  const friendData = {
    ...friend,
    haveAccess: false,
    database: [] as DatabaseRecord[],
  };
  if (authUser) {
    onValue(ref(getDatabase(), `${friend.id}/records/`),
      (snapshot: DataSnapshot) => {
        let dataDb = snapshot.val();
        friendData.haveAccess = true;
        if (dataDb === null || dataDb.length === 0) {
          errorMessage('dataDb: null || length === 0');
          onDone(friendData);
        } else {
          dataDb = Object.keys(dataDb).map((key: string) => dataDb[key]);
          onDone({ ...friendData, database: dataDb as DatabaseRecord[] });
        }
      },
      (cancelCallbackOrContext?: Record<string, any> | null | undefined) => {
        if (
          cancelCallbackOrContext?.code?.toUpperCase() === "PERMISSION_DENIED"
        ) {
          onDone(friendData);
        } else {
          errorMessage(`cancelCallbackOrContext: ${cancelCallbackOrContext}`);
          console.error(cancelCallbackOrContext);
        }
      }
    );
  } else {
    errorMessage('noAuthUser');
    onDone(friendData);
  }
};

export const removeFriend = (friend: User, onDone: () => void) => {
  if (authUser) {
    remove(ref(getDatabase(), `${authUser.uid}/friends/${friend.id}`))
      .then(() => {
        onComplete("Remove friend", onDone, null);
      })
      .catch((error) => {
        onComplete(`Remove friend failed: ${error}`, onDone, error);
      });
  } else {
    onComplete(`Remove friend failed - no authUser`, onDone, null);
  }
};

export const updateNick = (newNick: string, onDone: () => void) => {
  if (authUser) {
    set(ref(getDatabase(), `users/${authUser.uid}/`), newNick)
      .then(() => {
        onComplete("Update nick", onDone, null);
      })
      .catch((error) => {
        onComplete(`Update nick failed: ${error}`, onDone, error);
      });
  } else {
    onComplete(`Update nick failed - no authUser`, onDone, null);
  }
};

export const logOut = () => {
  Object.keys(window.localStorage)
    .filter((item) => item.startsWith("firebase"))
    .forEach((key) => {
      window.localStorage.removeItem(key);
    });
  getAuth().signOut();
};

export const setAuthUser = (newAuthUser: AuthUser) => {
  authUser = newAuthUser;
};
