import React from "react";

import TabComponent from "./TabComponent";

function TabComponentRow() {
  const inputComponent: React.ReactElement = <TabComponent />;
  return <>{inputComponent}</>;
}

export default TabComponentRow;
