import { Table } from "antd";
import React, { useEffect, useState } from "react";

import { PAIRS_COLUMNS } from "../../utils/columns";
import { DatabaseRecord, IPairs, PairTableRow } from "../../utils/types";
import { getPairs, getSortedPairs } from "../../utils/utils";

interface IStateProps {
  database: DatabaseRecord[];
}
interface IOwnProps {
  startPeriod: number;
  endPeriod: number;
  mostRecent: boolean;
}
interface IProps extends IStateProps, IOwnProps {}

function PairsTable(props: IProps = {} as IProps) {
  const { database, startPeriod, endPeriod, mostRecent } = props;

  const [pairs, setPairs] = useState(
    new Map<string, DatabaseRecord[]>() as IPairs
  );

  useEffect(() => {
    if (database) {
      const filteredDatabase = database.filter(
        (record: DatabaseRecord) =>
          record.date >= startPeriod && record.date <= endPeriod
      );
      if (mostRecent) {
        setPairs(getPairs(filteredDatabase));
      } else {
        // mostCommon
        setPairs(getSortedPairs(filteredDatabase));
      }
    }
  }, [database, startPeriod, endPeriod, mostRecent]);

  // [string, DatabaseRecord[]]
  const dataSource: PairTableRow[] = [...pairs.entries()].map((pair: any) => ({
    ...pair[1][0],
    records: pair[1].length,
  }));

  return (
    <>
      <h3>{mostRecent ? "Most recent" : "Most common"}</h3>
      <Table
        size={"small"}
        rowKey={(record: PairTableRow) => record.id}
        key="pairTable"
        loading={!props.database}
        dataSource={dataSource}
        columns={
          mostRecent
            ? PAIRS_COLUMNS.filter((column) => column.key !== "records")
            : PAIRS_COLUMNS
        }
        pagination={{ pageSize: 20 }}
        style={{ width: "100%" }}
      />
    </>
  );
}

export default PairsTable;
