import { Button, Cascader, Col, Input, InputNumber, Row } from "antd";
import React, { ReactText, useState } from "react";
import { connect } from "react-redux";

import { LOCALE_OBJECT } from "../utils/const";
import { getCoveredInCard } from "../utils/cover";
import { updateNick, updateSettings } from "../utils/firebaseDb";
import { AuthUser, Locale, Settings } from "../utils/types";

interface IStateProps {
  authUser: AuthUser;
  settings: Settings;
}

const options = Object.keys(LOCALE_OBJECT).map((localeKey: string) => {
  return {
    value: localeKey,
    label: LOCALE_OBJECT[localeKey as Locale],
  };
});

function SettingsComponent(props: IStateProps = {} as IStateProps) {
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState(props.settings.locale);
  const [goal, setGoal] = useState(props.settings.goal);
  const [nick, setNick] = useState(props.settings.nick);

  const onChangeLocale = (value: ReactText[]) => {
    value && value.length > 0 && setLocale(value[0] as Locale);
  };

  const onChangeGoal = (value: number | null) => {
    value && setGoal(value as number);
  };

  const onChangeNick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    value && setNick(value);
  };

  return getCoveredInCard(
    <>
      <Row justify="center" align="middle" style={{ padding: "12px 0" }}>
        <Col span={8}>
          <Row justify="start" align="middle">
            Date/Time format
          </Row>
        </Col>
        <Col span={16}>
          <Row justify="end" align="middle">
            <Cascader
              allowClear={false}
              defaultValue={[locale]}
              options={options}
              onChange={onChangeLocale}
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ padding: "12px 0" }}>
        <Col span={8}>
          <Row justify="start" align="middle">
            Daily goal
          </Row>
        </Col>
        <Col span={16}>
          <Row justify="end" align="middle">
            <InputNumber
              size={"middle"}
              min={1}
              max={100000}
              defaultValue={goal}
              onChange={onChangeGoal}
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ padding: "12px 0" }}>
        <Col span={8}>
          <Row justify="start" align="middle">
            Nick
          </Row>
        </Col>
        <Col span={16}>
          <Row justify="end" align="middle">
            <Input
              size={"middle"}
              placeholder="nick"
              defaultValue={nick}
              allowClear={false}
              onChange={onChangeNick}
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center" style={{ padding: "12px 0" }}>
        <Button
          type="primary"
          size={"middle"}
          onClick={() => {
            setLoading(true);
            updateSettings({ ...props.settings, locale, goal }, () =>
              setLoading(false)
            );
            if (props.settings.nick !== nick) {
              updateNick(nick, () => {
                /* update in the background */
              });
            }
          }}
          loading={loading}
          style={{ margin: "0 6px" }}
        >
          Update
        </Button>
      </Row>
    </>
  );
}

const mapStateToProps = (state: IStateProps): IStateProps => ({ ...state });

export default connect(mapStateToProps, null)(SettingsComponent);
