import { Button } from "antd";
import React, { useEffect, useState } from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { addNewRecord } from "../../../utils/firebaseDb";
import { processNotification } from "../../../utils/message";
import { IDefaultProps } from "../../../utils/types";
import { getCountChosenDays } from "../../../utils/utils";

function AddButton(props: IDefaultProps = {} as IDefaultProps) {
  const { loading, points, date, exerciseType, count } = props.addRecordState;

  const [countChosenDays, setCountChosenDays] = useState(0); // setup by getCountChosenDays

  useEffect(() => {
    if (props.database) {
      setCountChosenDays(getCountChosenDays(props.database, date));
    }
  }, [props.database, date]);

  const done = (count: number, countChosenDays: number) => () => {
    props.updateAddRecordState({ ...props.addRecordState, loading: false });
    processNotification(count, countChosenDays, props.settings.goal);
  };

  return (
    <Button
      type="primary"
      size={"middle"}
      onClick={() => {
        props.updateAddRecordState({ ...props.addRecordState, loading: true });
        addNewRecord(
          props.database,
          {
            count: points,
            date: date.valueOf(),
            type: exerciseType,
            realCount: count,
          },
          done(points, countChosenDays)
        );
      }}
      loading={loading}
      disabled={points <= 0}
    >
      Add new
    </Button>
  );
}

export default withDefaultState(AddButton);
