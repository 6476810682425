import "./index.css";
import "moment/locale/en-gb";

import { ConfigProvider } from "antd";
import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";

import MainRouter from "./MainRouter";
import { store } from "./reducer";
import * as serviceWorker from "./serviceWorker";

moment.locale("en_GB");
const myStore = createStore(store);
// myStore.subscribe(() => console.log(myStore.getState()));

ReactDOM.render(
  <Provider store={myStore}>
    <ConfigProvider locale={en_GB}>
      <MainRouter />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
