import React from "react";

import Summary from "../Components/Summary";
import { withDatePicker } from "../HOC/withDatePicker";
import { withDefaultState } from "../HOC/withDefaultState";
import { WithDatePickerProps } from "../utils/types";

function Statistics(props: WithDatePickerProps = {} as WithDatePickerProps) {
  const { allDates, date, picker } = props;

  return (
    <Summary picker={picker} date={date} extended={true} allDates={allDates} />
  );
}

export default withDefaultState(withDatePicker(Statistics));
