import "./row.css";

import { Col, Row } from "antd";
import React from "react";

export const get1ColumnRow = (
  inputComponent: React.ReactElement,
  customClass?: string
) => (
  <Row
    justify="center"
    align="middle"
    className={customClass ? customClass : "one-column-row"}
    key={"get1ColumnRow" + inputComponent.key}
  >
    <Col span={24}>
      <Row justify="center" align="middle">
        {inputComponent}
      </Row>
    </Col>
  </Row>
);

export const get2diffColumnsRow = (
  label: string,
  inputComponent: React.ReactElement,
  customClass?: string
) => (
  <Row
    justify="start"
    align="middle"
    className={customClass ? customClass : "two-column-row"}
    key={"get2diffColumnsRow" + label + inputComponent.key}
  >
    <Col span={8}>
      <Row justify="start" align="middle">
        {label}
      </Row>
    </Col>
    <Col span={16}>
      <Row justify="end" align="middle">
        {inputComponent}
      </Row>
    </Col>
  </Row>
);

export const get2sameColumnsRow = (
  left: React.ReactElement,
  right: React.ReactElement,
  customClass?: string
) => (
  <Row
    justify="center"
    align="middle"
    className={customClass ? customClass : "same-column-row"}
    key={"get2sameColumnsRow" + left.key + right.key}
  >
    <Col span={12}>
      <Row justify="start" align="middle">
        {left}
      </Row>
    </Col>
    <Col span={12}>
      <Row justify="end" align="middle">
        {right}
      </Row>
    </Col>
  </Row>
);

export const get2sameCenterColumnsRow = (
  left: React.ReactElement,
  right: React.ReactElement,
  customClass?: string
) => (
  <Row
    justify="center"
    align="middle"
    className={customClass ? customClass : "same-column-row"}
    key={"get2sameCenterColumnsRow" + left.key + right.key}
  >
    <Col span={12}>
      <Row justify="center" align="middle">
        {left}
      </Row>
    </Col>
    <Col span={12}>
      <Row justify="center" align="middle">
        {right}
      </Row>
    </Col>
  </Row>
);

export const get3ColumnsRows = (
  components: React.ReactElement[],
  customClass?: string
) => (
  <Row
    justify="start"
    align="middle"
    className={customClass ? customClass : "three-column-row"}
    key={"get3ColumnsRows" + components[0].key + components[1].key}
  >
    <Col span={8}>
      <Row justify="start" align="middle">
        {components[0]}
      </Row>
    </Col>
    <Col span={8}>
      <Row justify="center" align="middle">
        {components[1]}
      </Row>
    </Col>
    <Col span={8}>
      <Row justify="end" align="stretch">
        {components[2]}
      </Row>
    </Col>
  </Row>
);

export const get3CenterColumnsRows = (components: React.ReactElement[]) => (
  <Row
    justify="start"
    align="middle"
    className="three-column-row"
    key={"get3CenterColumnsRows" + components[0].key + components[1].key}
  >
    <Col span={8}>
      <Row justify="center" align="middle">
        {components[0]}
      </Row>
    </Col>
    <Col span={8}>
      <Row justify="center" align="middle">
        {components[1]}
      </Row>
    </Col>
    <Col span={8}>
      <Row justify="center" align="middle">
        {components[2]}
      </Row>
    </Col>
  </Row>
);
