import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { IDefaultProps } from "../../../utils/types";
import { haveToNormalizeTypeNumber } from "../../../utils/utils";
import NumericInput from "../../NumericInput";

function PointsInput(props: IDefaultProps = {} as IDefaultProps) {
  const { exerciseType, points } = props.addRecordState;
  const onChangeCount = (value: number) => {
    if (!haveToNormalizeTypeNumber(exerciseType)) {
      props.updateAddRecordState({
        ...props.addRecordState,
        count: value as number,
        points: value as number,
      });
    }
  };

  return (
    <NumericInput
      value={points}
      onChange={onChangeCount}
      disabled={haveToNormalizeTypeNumber(exerciseType)}
    />
  );
}

export default withDefaultState(PointsInput);
