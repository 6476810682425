import { Button } from "antd";
import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { IDefaultProps } from "../../../utils/types";

function DeleteButton(props: IDefaultProps = {} as IDefaultProps) {
  const { itemToDelete, loading } = props.addRecordState;

  return (
    <Button
      disabled={loading || !itemToDelete}
      danger
      size={"middle"}
      onClick={() => {
        props.updateAddRecordState({
          ...props.addRecordState,
          loading: true,
          showModal: true,
        });
      }}
    >
      Delete last
    </Button>
  );
}

export default withDefaultState(DeleteButton);
