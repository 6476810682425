import React from "react";

import PairsTable from "../Components/Pairs/PairsTable";
import { withDatePicker } from "../HOC/withDatePicker";
import { withDefaultState } from "../HOC/withDefaultState";
import { getCoveredInCard } from "../utils/cover";
import { get1ColumnRow } from "../utils/row";
import { DatabaseRecord, WithDatePickerProps } from "../utils/types";

const getInputComponent = (
  database: DatabaseRecord[],
  startPeriod: number,
  endPeriod: number,
  mostRecent: boolean
): React.ReactElement => (
  <PairsTable
    database={database}
    startPeriod={startPeriod}
    endPeriod={endPeriod}
    mostRecent={mostRecent}
    key={"PairsTable_mostRecent_" + mostRecent}
  />
);

function Pairs(props: WithDatePickerProps = {} as WithDatePickerProps) {
  const { database, startPeriod, endPeriod } = props;
  const mostCommonInputComponent: React.ReactElement = getInputComponent(
    database,
    startPeriod,
    endPeriod,
    false
  );
  const mostRecentInputComponent: React.ReactElement = getInputComponent(
    database,
    startPeriod,
    endPeriod,
    true
  );
  return (
    <>
      {getCoveredInCard(
        <>
          {get1ColumnRow(mostCommonInputComponent)}
          {get1ColumnRow(mostRecentInputComponent)}
        </>
      )}
    </>
  );
}

export default withDefaultState(withDatePicker(Pairs));
