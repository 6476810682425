import React from "react";
import { connect } from "react-redux";

import { actionTypes, getActionFunction } from "../actions";
import { DEFAULT_ADD_RECORD_STATE } from "../utils/const";
import {
  AuthUser,
  DatabaseRecord,
  Friend,
  IAddRecordStateProps,
  IDefaultDispatchProps,
  IDefaultProps,
  IDefaultState,
  Settings,
  User,
} from "../utils/types";
import { withAuthentication } from "./withAuthentication";
import { withDatabase } from "./withDatabase";

export const withDefaultState = (
  WrappedComponent: React.ComponentType<IDefaultProps>,
  ownProps?: any
) => {
  const mapStateToProps = (state: IDefaultState): IDefaultState => ({
    ...state,
  });

  const mapDispatchToProps = (dispatch: any): IDefaultDispatchProps => ({
    updateAddRecordState: (addRecordState: IAddRecordStateProps) =>
      dispatch(
        getActionFunction(actionTypes.UPDATE_ADD_RECORD_STATE, addRecordState)
      ),
    updateAuthUser: (authUser: AuthUser) =>
      dispatch(getActionFunction(actionTypes.UPDATE_AUTH_USER, authUser)),
    updateSettings: (settings: Settings) =>
      dispatch(getActionFunction(actionTypes.UPDATE_SETTINGS, settings)),
    updateDatabase: (database: DatabaseRecord[]) =>
      dispatch(getActionFunction(actionTypes.UPDATE_DATABASE, database)),
    updateUsers: (users: User[]) =>
      dispatch(getActionFunction(actionTypes.UPDATE_USERS, users)),
    updateFriends: (friends: Friend[]) =>
      dispatch(getActionFunction(actionTypes.UPDATE_FRIENDS, friends)),
    addFriend: (friend: Friend) =>
      dispatch(getActionFunction(actionTypes.ADD_FRIEND, friend)),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withAuthentication(
      withDatabase(
        class WithState extends React.Component<IDefaultProps, IDefaultProps> {
          componentDidMount() {
            if (!this.props.addRecordState) {
              this.props.updateAddRecordState(DEFAULT_ADD_RECORD_STATE);
            }
          }
          render() {
            return this.props.settings ? (
              <WrappedComponent {...this.props} {...ownProps} />
            ) : null;
          }
        }
      )
    )
  );
};
