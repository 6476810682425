import { Tabs } from "antd";
import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { IDefaultProps } from "../../../utils/types";
import PairsTab from "./PairsTab";
import PointsTab from "./PointsTab";

function TabComponent(props: IDefaultProps = {} as IDefaultProps) {
  const { tabChoice } = props.addRecordState;
  const { TabPane } = Tabs;
  const onTabChange = (selectedTab: string) => {
    props.updateAddRecordState({
      ...props.addRecordState,
      tabChoice: +selectedTab,
    });
  };

  return (
    <>
      <Tabs defaultActiveKey={`${tabChoice}`} onChange={onTabChange}>
        <TabPane tab="Points" key="1">
          <PointsTab />
        </TabPane>
        <TabPane tab="Common pairs" key="2">
          <PairsTab />
        </TabPane>
        <TabPane tab="Recent pairs" key="3">
          <PairsTab />
        </TabPane>
      </Tabs>
    </>
  );
}

export default withDefaultState(TabComponent);
