import { Button } from "antd";
import moment from "moment";
import React from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { get3ColumnsRows } from "../../../utils/row";
import { IDefaultProps } from "../../../utils/types";

function DayControllComponent(props: IDefaultProps = {} as IDefaultProps) {
  const { date } = props.addRecordState;
  const onDateChange = (momentDate: moment.Moment) => {
    momentDate &&
      props.updateAddRecordState({ ...props.addRecordState, date: momentDate });
  };

  return (
    <div style={{ width: "80%", maxWidth: "150px" }}>
      {get3ColumnsRows(
        [
          <LeftOutlined
            key="LeftOutlined"
            style={{ fontSize: "24px" }}
            onClick={() => onDateChange(date.subtract(1, "day"))}
          />,
          <Button
            key="Button"
            type="link"
            onClick={() => onDateChange(moment())}
            disabled={
              moment().clone().startOf("day") <= date &&
              date <= moment().clone().endOf("day")
            }
          >
            today
          </Button>,
          <RightOutlined
            key="RightOutlined"
            style={{ fontSize: "24px" }}
            onClick={() => onDateChange(date.add(1, "day"))}
            hidden={date >= moment().clone().startOf("day")}
          />,
        ],
        "three-column-row-no-padding"
      )}
    </div>
  );
}

export default withDefaultState(DayControllComponent);
