import { Divider } from "antd";
import React from "react";

import { get1ColumnRow } from "../../../utils/row";
import DeleteButton from "../DeleteButton/DeleteButton";
import PairsButtonsRow from "../PairsButtons/PairsButtonsRow";

function PairsTab() {
  return (
    <>
      <PairsButtonsRow />
      <Divider dashed />
      {get1ColumnRow(<DeleteButton />)}
    </>
  );
}

export default PairsTab;
