import moment from "moment";

import {
  ExerciseCategoryEnum,
  ExerciseType,
  IAddRecordStateProps,
  MenuItem,
  PathNameType,
  PickerType,
  Settings,
} from "./types";

// xs={<576px} sm={≥576px} md={≥768px} lg={≥992px} xl={≥1200px} xxl={≥1600px}
export const xs = 8;
export const sm = 6;
export const md = 5;
export const lg = 5;
export const xl = 3;
export const xxl = 2;

export const PICKER_TYPES: PickerType[] = ["date", "week", "month", "year"];

export const PATHS = {
  home: "/" as PathNameType,
  add: "/add" as PathNameType,
  addGrid: "/addGrid" as PathNameType,
  review: "/review" as PathNameType,
  compare: "/compare" as PathNameType,
  statistic: "/statistic" as PathNameType,
  pairs: "/pairs" as PathNameType,
  exercises: "/exercises" as PathNameType,
  users: "/users" as PathNameType,
  settings: "/settings" as PathNameType,
  about: "/about" as PathNameType,
  logout: "/logout" as PathNameType,
};

export const MENU: MenuItem[] = [
  { path: PATHS.home, title: "Home" },
  { path: PATHS.add, title: "Add" },
  { path: PATHS.addGrid, title: "Add [Grid]" },
  { path: PATHS.review, title: "Review" },
  { path: PATHS.compare, title: "Compare" },
  { path: PATHS.statistic, title: "Statistics" },
  { path: PATHS.pairs, title: "Pairs" },
  { path: PATHS.users, title: "Users" },
  { path: PATHS.exercises, title: "Exercises help" },
  { path: PATHS.settings, title: "Settings" },
  { path: PATHS.about, title: "About" },
  { path: PATHS.logout, title: "Logout" },
];

export const DEFAULT_SETTINGS: Settings = {
  goal: 100,
  locale: "cs-CZ",
  nick: "nick",
};
export const DEFAULT_ADD_RECORD_STATE: IAddRecordStateProps = {
  date: moment(),
  category: -1,
  exerciseType: -1,
  count: -1,
  points: -1,
  loading: false,
  showModal: false,
  itemToDelete: false,
  tabChoice: 1,
};

export const Exercises: ExerciseType = {
  PullUps: {
    name: "PullUps",
    id: 0,
    coefficient: 1,
    unit: "",
    categories: [ExerciseCategoryEnum.Arms],
  },
  PushUps: {
    name: "PushUps",
    id: 1,
    coefficient: 1,
    unit: "",
    categories: [
      ExerciseCategoryEnum.Arms,
      ExerciseCategoryEnum.Chest,
      ExerciseCategoryEnum.Shoulders,
    ],
  },
  Squats: {
    name: "Squats",
    id: 2,
    coefficient: 1,
    unit: "",
    categories: [
      ExerciseCategoryEnum.Legs,
      ExerciseCategoryEnum.Buttocks,
      ExerciseCategoryEnum.Hips,
    ],
  },
  SitUps: {
    name: "SitUps",
    id: 3,
    coefficient: 1,
    unit: "",
    categories: [ExerciseCategoryEnum.Abs],
  },
  Burpees: {
    name: "Burpees",
    id: 4,
    coefficient: 1,
    unit: "",
    categories: [
      ExerciseCategoryEnum.Arms,
      ExerciseCategoryEnum.Abs,
      ExerciseCategoryEnum.Back,
      ExerciseCategoryEnum.Buttocks,
      ExerciseCategoryEnum.Chest,
      ExerciseCategoryEnum.Hips,
      ExerciseCategoryEnum.Legs,
      ExerciseCategoryEnum.Shoulders,
    ],
  },
  HipThrust: {
    name: "HipThrust",
    id: 5,
    coefficient: 1,
    unit: "",
    categories: [ExerciseCategoryEnum.Buttocks],
  },
  Dumbbells: {
    name: "Dumbbells",
    id: 6,
    coefficient: 1,
    unit: "",
    categories: [
      ExerciseCategoryEnum.Arms,
      ExerciseCategoryEnum.Back,
      ExerciseCategoryEnum.Shoulders,
    ],
  },
  Dips: {
    name: "Dips",
    id: 7,
    coefficient: 1,
    unit: "",
    categories: [
      ExerciseCategoryEnum.Arms,
      ExerciseCategoryEnum.Shoulders,
    ],
  },
  Kickback: {
    name: "Kickback",
    id: 8,
    coefficient: 1,
    unit: "",
    categories: [ExerciseCategoryEnum.Buttocks, ExerciseCategoryEnum.Legs],
  },
  Lunges: {
    name: "Lunges",
    id: 9,
    coefficient: 1,
    unit: "",
    categories: [ExerciseCategoryEnum.Buttocks, ExerciseCategoryEnum.Legs],
  },
  Walk: {
    name: "Walk",
    id: 106,
    coefficient: 5,
    unit: "km",
    categories: [ExerciseCategoryEnum.Sports],
  },
  Run: {
    name: "Run",
    id: 107,
    coefficient: 15,
    unit: "km",
    categories: [ExerciseCategoryEnum.Sports],
  },
  Bike: {
    name: "Bike",
    id: 108,
    coefficient: 3,
    unit: "km",
    categories: [ExerciseCategoryEnum.Sports],
  },
  Swim: {
    name: "Swim",
    id: 109,
    coefficient: 0.1,
    unit: "m",
    categories: [ExerciseCategoryEnum.Sports],
  },
  RollerSkates: {
    name: "RollerSkates",
    id: 110,
    coefficient: 4,
    unit: "km",
    categories: [ExerciseCategoryEnum.Sports],
  },
  SMSystem: {
    name: "SMSystem",
    id: 111,
    coefficient: 1.5,
    unit: "min",
    categories: [ExerciseCategoryEnum.Sports],
  },
  Plank: {
    name: "Plank",
    id: 201,
    coefficient: 0.15,
    unit: "sec",
    categories: [ExerciseCategoryEnum.Abs],
  },
  Gym: {
    name: "Gym",
    id: 1000,
    coefficient: 3,
    unit: "min",
    categories: [ExerciseCategoryEnum.Sports],
  },
  Fitness: {
    name: "Fitness",
    id: 1001,
    coefficient: 3,
    unit: "min",
    categories: [ExerciseCategoryEnum.Sports],
  },
  Yoga: {
    name: "Yoga",
    id: 1002,
    coefficient: 1.5,
    unit: "min",
    categories: [ExerciseCategoryEnum.Sports],
  },
};

export const LOCALE_OBJECT = {
  "ar-SA": "Arabic (Saudi Arabia)",
  "bn-BD": "Bangla (Bangladesh)",
  "bn-IN": "Bangla (India)",
  "cs-CZ": "Czech (Czech Republic)",
  "da-DK": "Danish (Denmark)",
  "de-AT": "Austrian German",
  "de-CH": '"Swiss" German',
  "de-DE": "Standard German (as spoken in Germany)",
  "el-GR": "Modern Greek",
  "en-AU": "Australian English",
  "en-CA": "Canadian English",
  "en-GB": "British English",
  "en-IE": "Irish English",
  "en-IN": "Indian English",
  "en-NZ": "New Zealand English",
  "en-US": "US English",
  "en-ZA": "English (South Africa)",
  "es-AR": "Argentine Spanish",
  "es-CL": "Chilean Spanish",
  "es-CO": "Colombian Spanish",
  "es-ES": "Castilian Spanish (as spoken in Central-Northern Spain)",
  "es-MX": "Mexican Spanish",
  "es-US": "American Spanish",
  "fi-FI": "Finnish (Finland)",
  "fr-BE": "Belgian French",
  "fr-CA": "Canadian French",
  "fr-CH": '"Swiss" French',
  "fr-FR": "Standard French (especially in France)",
  "he-IL": "Hebrew (Israel)",
  "hi-IN": "Hindi (India)",
  "hu-HU": "Hungarian (Hungary)",
  "id-ID": "Indonesian (Indonesia)",
  "it-CH": '"Swiss" Italian',
  "it-IT": "Standard Italian (as spoken in Italy)",
  "jp-JP": "Japanese (Japan)",
  "ko-KR": "Korean (Republic of Korea)",
  "nl-BE": "Belgian Dutch",
  "nl-NL": "Standard Dutch (as spoken in The Netherlands)",
  "no-NO": "Norwegian (Norway)",
  "pl-PL": "Polish (Poland)",
  "pt-BR": "Brazilian Portuguese",
  "pt-PT": "European Portuguese (as written and spoken in Portugal)",
  "ro-RO": "Romanian (Romania)",
  "ru-RU": "Russian (Russian Federation)",
  "sk-SK": "Slovak (Slovakia)",
  "sv-SE": "Swedish (Sweden)",
  "ta-IN": "Indian Tamil",
  "ta-LK": "Sri Lankan Tamil",
  "th-TH": "Thai (Thailand)",
  "tr-TR": "Turkish (Turkey)",
  "zh-CN": "Mainland China, simplified characters",
  "zh-HK": "Hong Kong, traditional characters",
  "zh-TW": "Taiwan, traditional characters",
};
