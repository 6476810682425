import "./ExerciseHelp.css";

import { Card, Modal, Table } from "antd";
import React from "react";

import { Exercises } from "../utils/const";
import { getCoveredInCard } from "../utils/cover";
import { ExerciseDetail } from "../utils/types";

const dataSource: ExerciseDetail[] = Object.values(Exercises).map(
  (value) => value
);
const modalInfo = (exerciseName: string) => {
  Modal.info({
    title: exerciseName,
    content: (
      <Card hoverable={true}>
        <img
          src={`./icons/${exerciseName}.svg`}
          alt=""
          className="exercise-img exercise-img-full"
        />
      </Card>
    ),
    onOk() {},
  });
};
function ExercisesHelp() {
  const allColumns = [
    {
      title: "Icon",
      dataIndex: "name",
      key: "icon",
      render: (value: string) => (
        <img
          src={`./icons/${value}.svg`}
          alt=""
          className="exercise-img"
          onClick={() => {
            modalInfo(value);
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: ExerciseDetail, b: ExerciseDetail) =>
        a.name > b.name ? 1 : -1,
    },
    {
      title: "Points",
      dataIndex: "coefficient",
      key: "coefficient",
      sorter: (a: ExerciseDetail, b: ExerciseDetail) =>
        +a.coefficient - +b.coefficient,
      render: (value: number, exerciseDetail: ExerciseDetail) => (
        <div style={{ textAlign: "right" }}>
          {value}
          {exerciseDetail.coefficient === 1 ? "" : " per"}
        </div>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      sorter: (a: ExerciseDetail, b: ExerciseDetail) =>
        a.unit > b.unit ? 1 : -1,
    },
  ];

  return getCoveredInCard(
    <>
      <Table
        size={"small"}
        rowKey={(record: ExerciseDetail) => record.name + record.id}
        key="help"
        dataSource={dataSource}
        columns={allColumns}
        pagination={{ pageSize: 30 }}
      />
    </>
  );
}
export default ExercisesHelp;
