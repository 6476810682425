import { Table } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";

import { LoadingOutlined, StarFilled, StarOutlined } from "@ant-design/icons";

import { actionTypes, getActionFunction } from "../actions";
import { getCoveredInCard } from "../utils/cover";
import { addFriend, getFriendData, removeFriend } from "../utils/firebaseDb";
import {
  AuthUser,
  Friend,
  Settings,
  User,
  UserFriendRelation,
} from "../utils/types";

interface IStateProps {
  authUser: AuthUser;
  users: User[];
  friends: Friend[];
  settings: Settings;
}
interface IDispatchProps {
  updateFriends: (friends: Friend[]) => void;
  addFriend: (friends: Friend) => void;
}
interface IProps extends IStateProps, IDispatchProps {}

function Users(props: IProps = {} as IProps) {
  const [loading, setLoading] = useState(false);

  const friends = props.friends ? props.friends : [];
  const updateFriends = props.updateFriends;
  const allColumns = [
    {
      title: "User",
      dataIndex: "nick",
      key: "nick",
    },
    {
      title: "Allow access",
      key: "allowAccess",
      // eslint-disable-next-line react/display-name
      render: (record: UserFriendRelation) =>
        loading ? (
          <LoadingOutlined />
        ) : record.allowAccess ? (
          <StarFilled
            style={{ color: "#1DA57A" }}
            onClick={() => {
              // setRecordToProcess(record);
              // isNumber(recordToProcess.id) && deleteRecord(recordToProcess.id, () => setLoading(false));
              removeFriend(record, () => {
                const newFriends = [
                  ...friends.filter(
                    (friend: Friend) => friend.id !== record.id
                  ),
                ];
                updateFriends(newFriends);
                setLoading(false);
              });
              setLoading(true);
            }}
          />
        ) : (
          <StarOutlined
            style={{ color: "#1DA57A" }}
            onClick={() => {
              // setRecordToProcess(record);
              // isNumber(recordToProcess.id) && deleteRecord(recordToProcess.id, () => setLoading(false));
              addFriend(record as User, () => {
                getFriendData(record as User, (friend: Friend) => {
                  props.addFriend(friend);
                  setLoading(false);
                });
              });
              setLoading(true);
            }}
          />
        ),
    },
    {
      title: "Have access",
      key: "haveAccess",
      // eslint-disable-next-line react/display-name
      render: (record: UserFriendRelation) => {
        return record.haveAccess ? <StarFilled /> : <StarOutlined />;
      },
    },
  ];

  const dataSource: UserFriendRelation[] = props.users
    ?.filter((user: User) => user.id !== props.authUser?.uid)
    ?.map((user: User) => {
      const friend = friends.filter((friend: Friend) => friend.id === user.id);
      const isFriend = friend.length > 0;
      const haveAccess = isFriend && friend[0].haveAccess;
      return {
        ...user,
        allowAccess: isFriend,
        haveAccess,
      } as UserFriendRelation;
    });

  return getCoveredInCard(
    <>
      <Table
        size={"small"}
        rowKey={(record: UserFriendRelation) => record.nick}
        key="summary"
        loading={!props.users}
        dataSource={dataSource}
        columns={allColumns}
        pagination={{ pageSize: 10 }}
      />
    </>
  );
}

const mapStateToProps = (state: IStateProps): IStateProps => ({ ...state });

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
  updateFriends: (friends: Friend[]) =>
    dispatch(getActionFunction(actionTypes.UPDATE_FRIENDS, friends)),
  addFriend: (friend: Friend) =>
    dispatch(getActionFunction(actionTypes.ADD_FRIEND, friend)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
