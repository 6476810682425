import "./Navigation.css";

import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { MENU as MENU_ITEMS, PATHS } from "../utils/const";
import { MenuItem, PathNameType } from "../utils/types";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const { Sider } = Layout;

function Navigation() {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [path, setPath] = useState(PATHS.home);
  useEffect(() => {
    setPath(location.pathname as PathNameType);
  }, [location]);

  const isMobile = window.innerWidth <= 992; // lg
  const toggleCollapsed = () => {
    isMobile && setCollapsed(!collapsed);
  };

  const menuItems: ItemType[] = [{
    icon: <img src="icon.png" alt="icon" className="navigation-img" />,
    key: 'navigationImage',
    onClick: () => history.push(PATHS.home),
    className: "navigation-img"
  }];
  menuItems.push(...MENU_ITEMS.map((menuItem: MenuItem) =>
    ({ label: menuItem.title, key: menuItem.path, onClick: () => history.push(menuItem.path as PathNameType) })))

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onCollapse={toggleCollapsed}
      collapsed={collapsed}
      className="sider"
    >
      <Menu
        className="menu"
        theme="dark"
        mode="inline"
        selectedKeys={[path]}
        onClick={toggleCollapsed}
        items={menuItems}
      />

    </Sider>
  );
}

export default Navigation;
