import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import React from "react";

import LoadingComponent from "../Components/LoadingComponent";
import { setAuthUser } from "../utils/firebaseDb";
import { IDefaultProps } from "../utils/types";
import { SignInForm } from "../utils/signInForm";

export const withAuthentication = (
  WrappedComponent: React.ComponentType<IDefaultProps>
) => {
  return class WithAuthentication extends React.Component<
    IDefaultProps,
    IDefaultProps
  > {
    unsubscribeMethod: firebase.Unsubscribe = () => null;

    componentDidMount() {
      this.unsubscribeMethod = getAuth().onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.updateAuthUser(authUser);
        }
      });
    }

    componentWillUnmount() {
      if (this.unsubscribeMethod) {
        this.unsubscribeMethod();
      }
    }

    render() {
      const { authUser } = this.props;
      const cashedLogin = Object.keys(window.localStorage).filter((item) =>
        item.startsWith("firebase")
      )[0];
      authUser && setAuthUser(authUser);

      return authUser ? (
        <WrappedComponent {...this.props} />
      ) : (
        <LoadingComponent
          customComponent={cashedLogin ? undefined : <SignInForm />}
        />
      );
    }
  };
};
