import React from "react";

import { get2diffColumnsRow } from "../../../utils/row";
import PointsInput from "./PointsInput";

function PointsRow() {
  const title: string = "Points";
  const inputComponent: React.ReactElement = <PointsInput />;
  return <>{get2diffColumnsRow(title, inputComponent)}</>;
}

export default PointsRow;
