import { Card, Col, Row } from "antd";
import React from "react";

import { lg, md, sm, xl, xs, xxl } from "./const";

export const getCoveredInCard = (
  child: React.ReactElement,
  bodyStyle?: React.CSSProperties
) => (
  <Col span={24} key={child.key}>
    <Row justify="space-around">
      <Col
        xs={{ span: xs * 3 }}
        sm={{ span: sm * 3 }}
        md={{ span: md * 3 }}
        lg={{ span: lg * 3 }}
        xl={{
          span: xl * 3,
        }}
        xxl={{
          span: xxl * 3,
        }}
        style={{ padding: "12px" }}
      >
        <Card hoverable={false} bodyStyle={bodyStyle}>
          {child}
        </Card>
      </Col>
    </Row>
  </Col>
);
