import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actionTypes, getActionFunction } from "../actions";
import PairsButtonsRow from "../Components/AddRecord/PairsButtons/PairsButtonsRow";
import DeleteModal from "../Components/DeleteModal";
import Summary from "../Components/Summary";
import { getCoveredInCard } from "../utils/cover";
import { get1ColumnRow } from "../utils/row";
import {
  AuthUser,
  DatabaseRecord,
  IAddRecordStateProps,
  Settings,
} from "../utils/types";

interface IStateProps {
  addRecordState: IAddRecordStateProps;
  authUser: AuthUser;
  database: DatabaseRecord[];
  settings: Settings;
}
interface IDispatchProps {
  updateAddRecordState: (addRecordState: IAddRecordStateProps) => void;
  updateDatabase: (database: DatabaseRecord[]) => void;
  updateSettings: (settings: Settings) => void;
}
interface IProps extends IStateProps, IDispatchProps { }

function AddGrid(props: IProps = {} as IProps) {
  let date: moment.Moment = moment();
  if (props.addRecordState.date === undefined) {
    props.updateAddRecordState({ ...props.addRecordState, date });
  } else {
    date = props.addRecordState.date;
  }

  const { exerciseType, points, count, itemToDelete, showModal, loading } =
    props.addRecordState;

  useEffect(() => {
    if (props.database) {
      const length = props.database.length;
      if (length <= 0) {
        props.updateAddRecordState({
          ...props.addRecordState,
          exerciseType: 0,
          points: 0,
          count: 0,
        });
      } else {
        let newExerciseType = exerciseType;
        let newPoints = points;
        let newCount = count;
        const newItemToDelete = props.database[props.database.length - 1];
        let newShowModal = showModal;
        let newLoading = loading;
        let updatePoints: boolean = false;

        if (exerciseType === -1) {
          newExerciseType = props.database[length - 1].type;
          updatePoints = true;
        }
        if (points === -1) {
          newPoints = props.database[length - 1].count;
          updatePoints = true;
        }
        if (count === -1) {
          newCount = props.database[length - 1].realCount
            ? props.database[length - 1].realCount
            : props.database[length - 1].count;
          updatePoints = true;
        }
        if (!itemToDelete || itemToDelete !== newItemToDelete) {
          updatePoints = true;
        }
        if (
          showModal === undefined ||
          showModal !== props.addRecordState.showModal
        ) {
          newShowModal = showModal || false;
          updatePoints = true;
        }
        if (loading === undefined || loading !== props.addRecordState.loading) {
          newLoading = loading || false;
          updatePoints = true;
        }
        if (updatePoints) {
          props.updateAddRecordState({
            ...props.addRecordState,
            exerciseType: newExerciseType,
            points: newPoints,
            count: newCount,
            itemToDelete: newItemToDelete,
            loading: newLoading,
            showModal: newShowModal,
          });
        }
      }
    }
  }, [exerciseType, points, props, count, itemToDelete, loading, showModal]);

  return (
    <>
      <DeleteModal
        showModal={showModal}
        itemToDelete={itemToDelete}
        setShowModal={() => {
          props.updateAddRecordState({
            ...props.addRecordState,
            showModal: false,
          });
        }}
        setLoading={() =>
          props.updateAddRecordState({
            ...props.addRecordState,
            showModal: false,
            loading: false,
          })
        }
      />
      {getCoveredInCard(
          get1ColumnRow(
        <React.Fragment key="1">
          {/* <CategoryRow />
          <ExerciseRow />
          <Divider dashed />
          <DatePickerRow />
          <DayControllRow />
          <TimePickerRow />
          <TabComponentRow /> */}
          {/* <PairsTab /> */}
          <h3>{"Most common"}</h3>
          <PairsButtonsRow bigButtons={true} initialCount={15} mostRecentOverride={false} />
          <h3>{"Most recent"}</h3>
          <PairsButtonsRow bigButtons={true} initialCount={15} mostRecentOverride={true} />
        </React.Fragment>
      ))}
      {getCoveredInCard(<Summary picker={"date"} date={date} key="Summary" />)}
    </>
  );
}

const mapStateToProps = (state: IStateProps): IStateProps => ({ ...state });

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
  updateAddRecordState: (addRecordState: IAddRecordStateProps) =>
    dispatch(
      getActionFunction(actionTypes.UPDATE_ADD_RECORD_STATE, addRecordState)
    ),
  updateDatabase: (database: DatabaseRecord[]) =>
    dispatch(getActionFunction(actionTypes.UPDATE_DATABASE, database)),
  updateSettings: (settings: Settings) =>
    dispatch(getActionFunction(actionTypes.UPDATE_SETTINGS, settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGrid);
