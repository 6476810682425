import { Divider } from "antd";
import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { get2sameCenterColumnsRow } from "../../../utils/row";
import { IDefaultProps } from "../../../utils/types";
import { haveToNormalizeTypeNumber } from "../../../utils/utils";
import AddButton from "../AddButton/AddButton";
import CountRow from "../Count/CountRow";
import DeleteButton from "../DeleteButton/DeleteButton";
import PointsRow from "../Points/PointsRow";
import SliderRow from "../Slider/SliderRow";

function PointsTab(props: IDefaultProps = {} as IDefaultProps) {
  const { exerciseType } = props.addRecordState;
  return (
    <>
      <PointsRow />
      {haveToNormalizeTypeNumber(exerciseType) ? <CountRow /> : ""}
      <SliderRow isDec={true} />
      <SliderRow isDec={false} />
      <Divider dashed />
      {get2sameCenterColumnsRow(<DeleteButton />, <AddButton />)}
    </>
  );
}

export default withDefaultState(PointsTab);
