import React from "react";

import { get2diffColumnsRow } from "../../../utils/row";
import CategorySelect from "./CategorySelect";

function CategoryRow() {
  const title: string = "Category";
  const inputComponent: React.ReactElement = <CategorySelect />;
  return <>{get2diffColumnsRow(title, inputComponent)}</>;
}

export default CategoryRow;
