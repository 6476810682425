import "./Home.css";

import { Button, Table } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import LoadingComponent from "../Components/LoadingComponent";
import { withDefaultState } from "../HOC/withDefaultState";
import {
  SUMMARY_COLUMNS_HOME,
  SUMMARY_COLUMNS_NICK,
  SUMMARY_COLUMNS_NO_COUNTRENDER,
} from "../utils/columns";
import { MENU, PATHS } from "../utils/const";
import { getCoveredInCard } from "../utils/cover";
import { get1ColumnRow } from "../utils/row";
import {
  DatabaseRecord,
  ExerciseName,
  Friend,
  IDefaultProps,
  MenuItem,
  SummaryData,
} from "../utils/types";
import {
  getExerciseId,
  haveToNormalizeTypeNumber,
  mainSortFunction,
} from "../utils/utils";
import { successMessage } from "../utils/message";
import { getDatabase, goOnline, goOffline } from "firebase/database";

function Home(props: IDefaultProps = {} as IDefaultProps) {
  const { database, friends, settings } = props;

  useEffect(() => {
    const db = getDatabase();
    if (db) {
      goOffline(db);
      goOnline(db);
    }
  }, []);

  const filteredDatabase: { [key: string]: DatabaseRecord[] } = {};
  const filteredDatabase7: { [key: string]: DatabaseRecord[] } = {};
  const filteredDatabase10: { [key: string]: DatabaseRecord[] } = {};
  const startPeriod: number = moment().clone().startOf("day").valueOf();
  const start7Period: number = moment()
    .clone()
    .subtract(7, "days")
    .startOf("day")
    .valueOf();
  const start10Period: number = moment("10/10/2021", "DD/MM/YYYY").valueOf();
  const endPeriod: number = moment().clone().endOf("day").valueOf();

  const dateResults: {
    [key: string]: {
      [key: string]: { [key: string]: number };
    };
  } = {
    date: {},
    lastSeven: {},
    10: {},
  };

  const summaryDataForDate: { [key: string]: SummaryData[] } = {
    date: [],
    lastSeven: [],
    lastSevenRun: [],
    10: [],
  };

  if (database && friends) {
    const fullDatabase: { [key: string]: DatabaseRecord[] } = {};
    fullDatabase[settings.nick] = [...database];
    friends
      .filter((f: Friend) => f.haveAccess)
      .forEach(
        (friend: Friend) => (fullDatabase[friend.nick] = [...friend.database])
      );

    Object.keys(fullDatabase).forEach((nick: string) => {
      filteredDatabase[nick] = fullDatabase[nick].filter(
        (record: DatabaseRecord) =>
          record.date >= startPeriod && record.date <= endPeriod
      );
    });
    Object.keys(fullDatabase).forEach((nick: string) => {
      filteredDatabase7[nick] = fullDatabase[nick].filter(
        (record: DatabaseRecord) =>
          record.date >= start7Period && record.date <= endPeriod
      );
    });
    Object.keys(fullDatabase).forEach((nick: string) => {
      filteredDatabase10[nick] = fullDatabase[nick].filter(
        (record: DatabaseRecord) =>
          record.date >= start10Period && record.date <= endPeriod
      );
    });

    Object.keys(filteredDatabase).forEach((nick: string) => {
      const dateString = "date";
      dateResults[dateString][nick] = {};
      filteredDatabase[nick].forEach((record: DatabaseRecord) => {
        const { type } = record;
        const { count, realCount } = record;

        let results = dateResults[dateString][nick];
        results =
          results === undefined
            ? { finished: count }
            : {
                ...results,
                finished:
                  results.finished === undefined
                    ? count
                    : results.finished + count,
              };
        results = {
          ...results,
          [type]:
            results[type] === undefined
              ? haveToNormalizeTypeNumber(type)
                ? realCount
                : count
              : results[type] +
                (haveToNormalizeTypeNumber(type) ? realCount : count),
        };
        dateResults[dateString][nick] = results;
      });
    });

    Object.keys(filteredDatabase7).forEach((nick: string) => {
      const dateString = "lastSeven";
      dateResults[dateString][nick] = {};
      filteredDatabase7[nick].forEach((record: DatabaseRecord) => {
        const { type } = record;
        const { count, realCount } = record;

        let results = dateResults[dateString][nick];
        results =
          results === undefined
            ? { finished: count }
            : {
                ...results,
                finished:
                  results.finished === undefined
                    ? count
                    : results.finished + count,
              };
        results = {
          ...results,
          [type]:
            results[type] === undefined
              ? haveToNormalizeTypeNumber(type)
                ? realCount
                : count
              : results[type] +
                (haveToNormalizeTypeNumber(type) ? realCount : count),
        };
        dateResults[dateString][nick] = results;
      });
    });

    Object.keys(filteredDatabase10).forEach((nick: string) => {
      const dateString = 10;
      dateResults[dateString][nick] = {};
      filteredDatabase10[nick].forEach((record: DatabaseRecord) => {
        const { type } = record;

        let results = dateResults[dateString][nick];
        results = {
          ...results,
          [type]: results[type] === undefined ? 1 : results[type] + 1,
        };
        dateResults[dateString][nick] = results;
      });
    });

    Object.keys(dateResults.date).forEach((nick: string) => {
      dateResults.date[nick][getExerciseId("Run")] &&
        summaryDataForDate.date.push({
          nick,
          type: "Run" as ExerciseName,
          count:
            Math.round(dateResults.date[nick][getExerciseId("Run")] * 10) / 10,
          avg: "",
          min: "",
          max: "",
        });
      dateResults.date[nick][getExerciseId("Gym")] &&
        summaryDataForDate.date.push({
          nick,
          type: "Gym" as ExerciseName,
          count:
            Math.round(dateResults.date[nick][getExerciseId("Gym")] * 10) / 10,
          avg: "",
          min: "",
          max: "",
        });
      dateResults.date[nick]["finished"] &&
        summaryDataForDate.date.push({
          nick,
          type: "Finished" as ExerciseName,
          count: Math.round(dateResults.date[nick]["finished"]),
          avg: "",
          min: "",
          max: "",
        });
    });

    Object.keys(dateResults.lastSeven).forEach((nick: string) => {
      dateResults.lastSeven[nick][getExerciseId("Run")] &&
        summaryDataForDate.lastSevenRun.push({
          nick,
          type: "Run" as ExerciseName,
          count:
            Math.round(dateResults.lastSeven[nick][getExerciseId("Run")] * 10) /
            10,
          avg: "",
          min: "",
          max: "",
        });
      dateResults.lastSeven[nick]["finished"] &&
        summaryDataForDate.lastSeven.push({
          nick,
          type: "Finished" as ExerciseName,
          count: Math.round(dateResults.lastSeven[nick]["finished"]),
          avg: "",
          min: "",
          max: "",
        });
    });

    Object.keys(dateResults[10]).forEach((nick: string) => {
      dateResults[10][nick][getExerciseId("Gym")] &&
        summaryDataForDate[10].push({
          nick,
          type: "Gym" as ExerciseName,
          count:
            Math.round(dateResults[10][nick][getExerciseId("Gym")] * 10) / 10,
          avg: "",
          min: "",
          max: "",
        });
    });
    Object.keys(summaryDataForDate).forEach((sumaryKey) =>
      summaryDataForDate[sumaryKey].sort(mainSortFunction)
    );
  } else {
    return <LoadingComponent />;
  }

  return (
    <>
      {[
        get1ColumnRow(
          <Link to={PATHS.add} key="">
            <img src="icon.png" alt="icon" className="home-img" />
          </Link>
        ),
        getCoveredInCard(
          <>
            {get1ColumnRow(
              <>
                <Button className="home-button" key={PATHS.add}>
                  <Link to={PATHS.add}>
                    {
                      MENU.filter(
                        (item: MenuItem) => item.path === PATHS.add
                      )[0].title
                    }
                  </Link>
                </Button>
                <Button className="home-button" key={PATHS.addGrid}>
                  <Link to={PATHS.addGrid}>
                    {
                      MENU.filter(
                        (item: MenuItem) => item.path === PATHS.addGrid
                      )[0].title
                    }
                  </Link>
                </Button>
                <Button className="home-button" key={PATHS.compare}>
                  <Link to={PATHS.compare}>
                    {
                      MENU.filter(
                        (item: MenuItem) => item.path === PATHS.compare
                      )[0].title
                    }
                  </Link>
                </Button>
              </>
            )}
          </>
        ),
        getCoveredInCard(
          get1ColumnRow(
            <React.Fragment key="t">
              <h3>{"Today"}</h3>
              <Table
                size={"small"}
                rowKey={(record: SummaryData) =>
                  record.type + record.nick + "t"
                }
                loading={!props.database}
                dataSource={summaryDataForDate.date}
                rowClassName={(_record: SummaryData) =>
                  _record.type === "Finished"
                    ? "day-background"
                    : "ant-table-cell"
                }
                columns={SUMMARY_COLUMNS_HOME}
                pagination={false}
                style={{ width: "100%" }}
              />
            </React.Fragment>
          )
        ),
        getCoveredInCard(
          get1ColumnRow(
            <React.Fragment key="7">
              <h3>{"Last 7 days"}</h3>
              <Table
                size={"small"}
                rowKey={(record: SummaryData) =>
                  record.type + record.nick + "7"
                }
                loading={!props.database}
                dataSource={summaryDataForDate.lastSeven}
                rowClassName={(_record: SummaryData) =>
                  _record.type === "Finished"
                    ? "day-background"
                    : "ant-table-cell"
                }
                columns={SUMMARY_COLUMNS_HOME}
                pagination={false}
                style={{ width: "100%" }}
              />
            </React.Fragment>
          )
        ),
        getCoveredInCard(
          get1ColumnRow(
            <React.Fragment key="8">
              <h3>{"Last 7 days RUN"}</h3>
              <Table
                size={"small"}
                rowKey={(record: SummaryData) =>
                  record.type + record.nick + "7"
                }
                loading={!props.database}
                dataSource={summaryDataForDate.lastSevenRun}
                rowClassName={(_record: SummaryData) =>
                  _record.type === "Finished"
                    ? "day-background"
                    : "ant-table-cell"
                }
                columns={SUMMARY_COLUMNS_HOME}
                pagination={false}
                style={{ width: "100%" }}
              />
            </React.Fragment>
          )
        ),
        getCoveredInCard(
          get1ColumnRow(
            <React.Fragment key="10">
              <h3>{"GYM from 10/10/21"}</h3>
              <Table
                size={"small"}
                rowKey={(record: SummaryData) =>
                  record.type + record.nick + "10"
                }
                loading={!props.database}
                dataSource={summaryDataForDate[10]}
                rowClassName={(_record: SummaryData) =>
                  _record.type === "Finished"
                    ? "day-background"
                    : "ant-table-cell"
                }
                columns={[
                  ...SUMMARY_COLUMNS_NICK,
                  ...SUMMARY_COLUMNS_NO_COUNTRENDER,
                ]}
                pagination={false}
                style={{ width: "100%" }}
              />
            </React.Fragment>
          )
        ),
        getCoveredInCard(
          get1ColumnRow(
            <>
              <Button
                className="home-button"
                key={"user"}
                onClick={() => {
                  const db = getDatabase();
                  goOnline(db);
                  successMessage(`db online`);
                }}
              >
                db online
              </Button>
              <Button
                className="home-button"
                key={"user"}
                onClick={() => {
                  const db = getDatabase();
                  goOffline(db);
                  successMessage(`db offline`);
                }}
              >
                db offline
              </Button>
            </>
          )
        ),
      ]}
    </>
  );
}

export default withDefaultState(Home);
