import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./Pages/Main";
import { firebaseInit } from "./firebaseInit";

export default function MainRouter() {
  firebaseInit();
  return (
    <Router>
      <Route path="/">
        <Main />
      </Route>
    </Router>
  );
}
