import { Table } from "antd";
import React, { useEffect, useState } from "react";

import Chart from "../Components/Chart";
import Summary from "../Components/Summary";
import { withDatePicker } from "../HOC/withDatePicker";
import { withDefaultState } from "../HOC/withDefaultState";
import { COLUMNS } from "../utils/columns";
import { getCoveredInCard } from "../utils/cover";
import { DatabaseRecord, WithDatePickerProps } from "../utils/types";

function Review(props: WithDatePickerProps = {} as WithDatePickerProps) {
  const [filteredDatabase, setFilteredDatabase] = useState(
    [] as DatabaseRecord[]
  );
  const { database, date, picker, startPeriod, endPeriod } = props;

  useEffect(() => {
    if (database) {
      const fd: DatabaseRecord[] = database.filter(
        (record: DatabaseRecord) =>
          record.date >= startPeriod && record.date <= endPeriod
      );
      setFilteredDatabase(fd);
    }
  }, [database, startPeriod, endPeriod]);

  // -------------------------

  return (
    <>
      {getCoveredInCard(<Summary picker={picker} date={date} key="Summary" />)}
      {getCoveredInCard(
        <Chart
          picker={picker}
          date={date}
          filteredDatabase={filteredDatabase}
          key="Chart"
        />,
        {
          padding: "12px 2px",
        }
      )}
      {getCoveredInCard(
        <Table
          size={"small"}
          key="Table"
          rowKey={(record: DatabaseRecord) => record.date + record.id}
          loading={!props.database}
          dataSource={filteredDatabase}
          // @ts-ignore
          columns={COLUMNS(props.settings.locale)}
          pagination={filteredDatabase.length > 9 ? { pageSize: 10 } : false}
        />
      )}
    </>
  );
}

export default withDefaultState(withDatePicker(Review));
