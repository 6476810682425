import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { Theme } from "@nivo/core";

import {
  ChartData,
  DatabaseRecord,
  PickerType,
  Settings,
} from "../utils/types";
import { getExerciseArray, getExerciseName } from "../utils/utils";

interface IStateProps {
  database: DatabaseRecord[];
  settings: Settings;
}
interface IOwnProps {
  picker: PickerType;
  date: Moment;
  filteredDatabase: DatabaseRecord[];
}
interface IProps extends IStateProps, IOwnProps {}

const MONTHS_IN_YEAR = 12;
function Chart(props: IProps = {} as IProps) {
  const { date, database, filteredDatabase, picker } = props;
  const [days, setDays] = useState([] as ChartData[]);

  const countDaysOrMonths =
    picker === "date"
      ? 1
      : picker === "week"
      ? 7
      : picker === "month"
      ? date.daysInMonth()
      : MONTHS_IN_YEAR;
  useEffect(() => {
    const d: ChartData[] = [];
    const columns = picker === "year" ? MONTHS_IN_YEAR : countDaysOrMonths;
    const addUnit = picker === "year" ? "months" : "days";
    const dateStringFormat =
      picker === "year" ? "M" : picker === "month" ? "D" : "D. M.";
    for (let i = 0; i < columns; i++) {
      const cd: ChartData = {} as ChartData;
      const countedDate: moment.Moment = date
        .clone()
        .startOf(picker)
        .add(i, addUnit);
      const exerciseArray = getExerciseArray();
      for (let i = 0; i < exerciseArray.length; i++) {
        cd[exerciseArray[i]] = 0;
      }

      cd.date = countedDate.valueOf();
      cd.dateString = countedDate.format(dateStringFormat);
      d.push(cd as ChartData);
    }
    filteredDatabase.forEach((record: DatabaseRecord) => {
      for (let i = 0; i < countDaysOrMonths; i++) {
        if (i === countDaysOrMonths - 1 || record.date < d[i + 1].date) {
          d[i][getExerciseName(record.type)] += record.count;
          break;
        }
      }
    });
    setDays(d);
  }, [database, filteredDatabase, countDaysOrMonths, date, picker]);

  const theme: Theme = {
    tooltip: {
      tableCell: {
        background: "red",
      },
      container: {
        background: "black",
      },
      basic: {
        background: "black",
      },
    },
  };
  return (
    <div style={{ height: "50vh" }}>
      <ResponsiveBar
        theme={theme}
        data={days as any as BarDatum[]}
        keys={getExerciseArray()}
        indexBy="dateString"
        margin={{ top: 10, right: 0, bottom: 60, left: 25 }}
        padding={0.1}
        borderWidth={0}
        colors={{ scheme: "dark2" }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "count",
            },
            id: "count",
          },
        ]}
        borderColor={{ from: "color", modifiers: [["darker", 2]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 2,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 0,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 2,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 0,
        }}
        labelSkipWidth={10}
        labelSkipHeight={10}
        labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        label={(d) =>
          d.value! > 999 ? `${Math.round(+d.value! / 100) / 10}k` : `${d.value}`
        }
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 40,
            itemsSpacing: 0,
            itemWidth: 50,
            itemHeight: 15,
            itemDirection: "top-to-bottom",
            itemOpacity: 0.85,
            symbolSize: 15,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
            itemTextColor: "white",
          },
        ]}
        animate={true}
        // motionStiffness={90}
        // motionDamping={15}
      />
    </div>
  );
}

const mapStateToProps = (state: IStateProps, ownProps: IOwnProps): IProps => ({
  ...state,
  ...ownProps,
});

export default connect(mapStateToProps, null)(Chart);
