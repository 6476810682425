import React from "react";

import { Exercises } from "./const";
import {
  DatabaseRecord,
  ExerciseDetail,
  ExerciseName,
  Locale,
  PairTableRow,
  SummaryData,
} from "./types";
import { getExerciseArray, getExerciseName } from "./utils";

const renderCount = (value: number, row: SummaryData) =>
  row.type.toLowerCase() === "todo" ? (
    <div style={{ color: value > 0 ? "#d50000" : "#00c853" }}>
      {value > 0 ? value : 0}
    </div>
  ) : (
    value +
    (Exercises[row.type as ExerciseName] &&
    Exercises[row.type as ExerciseName].unit.length > 0
      ? Exercises[row.type as ExerciseName].unit
      : "")
  );

export const COLUMNS = (localeString: Locale) => [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (value: number) => new Date(value).toLocaleDateString(localeString),
    sorter: (a: DatabaseRecord, b: DatabaseRecord) => {
      return +a.date - +b.date;
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type: number) => {
      return Exercises[getExerciseName(type)].name;
    },
    filters: getExerciseArray().map((exercise: string) => ({
      text: exercise,
      value: Exercises[exercise].id,
    })),
    filterMultiple: true,
    onFilter: (value: number, record: DatabaseRecord) => record.type === value,
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
    render: (count: number, record: DatabaseRecord) => {
      const currentExercise: ExerciseDetail =
        Exercises[getExerciseName(record.type)];
      return currentExercise.coefficient !== 1
        ? record.realCount + currentExercise.unit
        : count;
    },
    sorter: (a: DatabaseRecord, b: DatabaseRecord) => {
      return +a.count - +b.count;
    },
  },
];

export const SUMMARY_COLUMNS = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
    // eslint-disable-next-line react/display-name
    render: renderCount,
  },
];

export const SUMMARY_COLUMNS_NO_COUNTRENDER = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
  },
];

const SUMMARY_COLUMNS_EXTENDED_ONLY = [
  {
    title: "Avg",
    dataIndex: "avg",
    key: "avg",
  },
  {
    title: "Min",
    dataIndex: "min",
    key: "min",
  },
  {
    title: "Max",
    dataIndex: "max",
    key: "max",
  },
];

export const SUMMARY_COLUMNS_EXTENDED = [
  ...SUMMARY_COLUMNS,
  ...SUMMARY_COLUMNS_EXTENDED_ONLY,
];

export const SUMMARY_COLUMNS_NICK = [
  {
    title: "Nick",
    dataIndex: "nick",
    key: "nick",
  },
];
export const SUMMARY_COLUMNS_HOME = [
  ...SUMMARY_COLUMNS_NICK,
  ...SUMMARY_COLUMNS,
];

export const SUMMARY_COLUMNS_FRIEND = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Me",
    dataIndex: "count",
    key: "count",
    // eslint-disable-next-line react/display-name
    render: renderCount,
    sorter: (a: SummaryData, b: SummaryData) => +a.count - +b.count,
  },
  {
    title: "Friend",
    dataIndex: "friend",
    key: "friend",
    // eslint-disable-next-line react/display-name
    render: renderCount,
    sorter: (a: SummaryData, b: SummaryData) => +a.count - +b.count,
  },
];

export const PAIRS_COLUMNS = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type: number) => {
      return Exercises[getExerciseName(type)].name;
    },
    sorter: (a: PairTableRow, b: PairTableRow) => {
      return getExerciseName(a.type) < getExerciseName(b.type) ? -1 : 1;
    },
    filters: getExerciseArray().map((exercise: string) => ({
      text: exercise,
      value: Exercises[exercise].id,
    })),
    filterMultiple: true,
    onFilter: (value: string | number | boolean, record: PairTableRow) =>
      record.type === value,
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
    render: (count: number, record: PairTableRow) => {
      const currentExercise: ExerciseDetail =
        Exercises[getExerciseName(record.type)];
      return currentExercise.coefficient !== 1
        ? record.realCount + currentExercise.unit
        : count;
    },
    sorter: (a: PairTableRow, b: PairTableRow) => {
      return +a.count - +b.count;
    },
  },
  {
    title: "records",
    dataIndex: "records",
    key: "records",
    sorter: (a: PairTableRow, b: PairTableRow) => {
      return +a.records - +b.records;
    },
  },
];
