import React from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { Exercises } from "../../../utils/const";
import { get2diffColumnsRow } from "../../../utils/row";
import { ExerciseName, IDefaultProps } from "../../../utils/types";
import { getExerciseName } from "../../../utils/utils";
import CountInput from "./CountInput";

function CountRow(props: IDefaultProps = {} as IDefaultProps) {
  const { exerciseType } = props.addRecordState;
  const title: string =
    Exercises[getExerciseName(exerciseType) as ExerciseName].unit +
    " * " +
    Exercises[getExerciseName(exerciseType) as ExerciseName].coefficient;
  const inputComponent: React.ReactElement = <CountInput />;
  return <>{get2diffColumnsRow(title, inputComponent)}</>;
}

export default withDefaultState(CountRow);
