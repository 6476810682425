import { actionTypes } from "./actions";
import { IDefaultState } from "./utils/types";

const initialState: IDefaultState = {} as IDefaultState;

export const store = (state: IDefaultState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTH_USER:
      return { ...state, authUser: action.data };
    case actionTypes.UPDATE_SETTINGS:
      return { ...state, settings: action.data };
    case actionTypes.UPDATE_DATABASE:
      return { ...state, database: action.data };
    case actionTypes.UPDATE_USERS:
      return { ...state, users: action.data };
    case actionTypes.UPDATE_FRIENDS:
      return { ...state, friends: action.data };
    case actionTypes.ADD_FRIEND:
      return {
        ...state,
        friends: state.friends
          ? [
              ...state.friends.filter((f) => f.id !== action.data.id),
              action.data,
            ]
          : [action.data],
      };
    case actionTypes.UPDATE_ADD_RECORD_STATE:
      return {
        ...state,
        addRecordState: {
          ...action.data,
          points: Math.floor(+action.data.points),
        },
      };

    default:
      return state;
  }
};
