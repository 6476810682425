import { Select } from "antd";
import React, { useState } from "react";

import Summary from "../Components/Summary";
import { withDatePicker } from "../HOC/withDatePicker";
import { withDefaultState } from "../HOC/withDefaultState";
import { getCoveredInCard } from "../utils/cover";
import { updateSettings } from "../utils/firebaseDb";
import { get2diffColumnsRow } from "../utils/row";
import { Friend, WithDatePickerProps } from "../utils/types";

const { Option } = Select;

function Compare(props: WithDatePickerProps = {} as WithDatePickerProps) {
  const [loading, setLoading] = useState(false);
  const [friendId, setFriendId] = useState(
    props.settings.lastFriendId || "select friend"
  );

  const { date, picker } = props;

  const handleSelectFriend = (friendId: string) => {
    setLoading(true);
    updateSettings({ ...props.settings, lastFriendId: friendId }, () =>
      setLoading(false)
    );
    friendId && setFriendId(friendId);
  };

  return (
    <>
      {getCoveredInCard(
        get2diffColumnsRow(
          "Friend",
          <Select
            value={friendId}
            size={"middle"}
            loading={loading}
            onChange={handleSelectFriend}
          >
            {props.friends &&
              props.friends
                .filter((friend: Friend) => friend.haveAccess)
                .map((friend: Friend, index: number) => (
                  <Option value={friend.id} key={index}>
                    {friend.nick}
                  </Option>
                ))}
          </Select>
        )
      )}
      {getCoveredInCard(
        <Summary picker={picker} date={date} friendId={friendId} />
      )}
    </>
  );
}

export default withDefaultState(withDatePicker(Compare));
