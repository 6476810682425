import React from "react";
import { connect } from "react-redux";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { get1ColumnRow } from "../../../utils/row";
import {
  DatabaseRecord,
  IAddRecordStateProps,
  IDefaultProps,
  IDefaultState,
  Settings,
} from "../../../utils/types";
import PairsButtons from "./PairsButtons";

interface IOwnProps {
  mostRecentOverride?: boolean;
  bigButtons?: boolean;
  initialCount?: number;
}
interface IProps extends IDefaultProps, IOwnProps {}

const getInputComponent = (
  database: DatabaseRecord[],
  addRecordState: IAddRecordStateProps,
  settings: Settings,
  updateAddRecordState: (addRecordState: IAddRecordStateProps) => void,
  mostRecent: boolean,
  initialCount?: number
): React.ReactElement => (
  <PairsButtons
    database={database}
    addRecordState={addRecordState}
    settings={settings}
    updateAddRecordState={updateAddRecordState}
    mostRecent={mostRecent}
    initialCount={initialCount}
    key={"PairsButtons_mostRecent_" + mostRecent}
  />
);

function PairsButtonsRow(props: IProps = {} as IProps) {
  const {
    database,
    addRecordState,
    settings,
    mostRecentOverride,
    initialCount,
    updateAddRecordState,
  } = props;
  const mostCommonInputComponent: React.ReactElement = getInputComponent(
    database,
    addRecordState,
    settings,
    updateAddRecordState,
    mostRecentOverride ?? addRecordState.tabChoice === 3, // Tab 1 points, Tab 2 most common, Tab 3 is most recent
    initialCount
  );
  return (
    <>{get1ColumnRow(mostCommonInputComponent, "one-column-row-no-padding")}</>
  );
}

const mapStateToProps = (
  state: IDefaultState,
  ownProps: IOwnProps
): IOwnProps => ({
  ...state,
  ...ownProps,
});

export default connect(mapStateToProps, null)(withDefaultState(PairsButtonsRow));