import "./ExerciseSelect.css";

import { Row, Select } from "antd";
import React, { useCallback, useEffect } from "react";

import { withDefaultState } from "../../../HOC/withDefaultState";
import { Exercises } from "../../../utils/const";
import {
  ExerciseCategoryEnum,
  ExerciseDetail,
  ExerciseName,
  IDefaultProps,
} from "../../../utils/types";
import {
  getExerciseArray,
  getExerciseArraySorted,
  getExerciseId,
  getExerciseName,
} from "../../../utils/utils";

const { Option } = Select;

function ExerciseSelect(props: IDefaultProps = {} as IDefaultProps) {
  const { category, count, exerciseType } = props.addRecordState;

  // keep function reference
  const handleSelectExercise = useCallback(
    (value: number) => {
      const points: number =
        (count as number) *
        Exercises[getExerciseName(value) as ExerciseName].coefficient;
      props.updateAddRecordState({
        ...props.addRecordState,
        points,
        exerciseType: value,
      });
    },
    [props, count]
  );

  useEffect(() => {
    if (
      category !== -1 &&
      Exercises[getExerciseName(exerciseType)].categories.indexOf(category) < 0
    ) {
      const exerciseArray: ExerciseName[] = getExerciseArray();
      for (let i = 0; i < exerciseArray.length; i++) {
        const exercise: ExerciseDetail = Exercises[exerciseArray[i]];
        if (exercise.id === 4 && category !== ExerciseCategoryEnum.Back) {
          continue;
        }
        if (exercise.categories.indexOf(category) >= 0) {
          handleSelectExercise(exercise.id);
        }
      }
    }
  }, [handleSelectExercise, exerciseType, category]);

  return (
    <Select
      defaultValue={exerciseType}
      value={exerciseType}
      size={"middle"}
      onChange={handleSelectExercise}
      className="exercise-select"
      listItemHeight={1}
    >
      {getExerciseArraySorted()
        .filter(
          (exerciseName: ExerciseName) =>
            category === -1 ||
            Exercises[exerciseName].categories.indexOf(category) >= 0
        )
        .map((exerciseName: ExerciseName) => (
          <Option value={getExerciseId(exerciseName)} key={exerciseName}>
            <Row justify="start" align="middle" className="exercise-row">
              <img
                src={`./icons/${exerciseName}.svg`}
                alt=""
                className="exercise-img"
              />
              <div className="exercise-option-div">{exerciseName}</div>
            </Row>
          </Option>
        ))}
    </Select>
  );
}

export default withDefaultState(ExerciseSelect);
