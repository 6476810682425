import "./Summary.css";

import { Row, Table, Tabs } from "antd";
import moment, { Duration, Moment, unitOfTime } from "moment";
import React from "react";
import { connect } from "react-redux";

import {
  SUMMARY_COLUMNS,
  SUMMARY_COLUMNS_EXTENDED,
  SUMMARY_COLUMNS_FRIEND,
} from "../utils/columns";
import { Exercises } from "../utils/const";
import { getCoveredInCard } from "../utils/cover";
import {
  DatabaseRecord,
  ExerciseName,
  Friend,
  IDefaultState,
  PickerType,
  Settings,
  SummaryData,
} from "../utils/types";
import {
  getExerciseArray,
  getExerciseId,
  getGoal,
  haveToNormalizeTypeNumber,
  sortedDatabase,
} from "../utils/utils";

interface IStateProps {
  database: DatabaseRecord[];
  settings: Settings;
  friends: Friend[];
}
interface IOwnProps {
  picker: PickerType;
  date: Moment;
  allDates?: boolean;
  friendId?: string;
  extended?: boolean;
}
interface IProps extends IStateProps, IOwnProps {}

function Summary(props: IProps = {} as IProps) {
  const { allDates, date, database } = props;
  let filteredDatabase: DatabaseRecord[] = [];
  let frFilteredDatabase: DatabaseRecord[] = [];
  let countChosenDay = 0;
  // let countMin = 0;
  // let countMax = 0;
  let frCountChosenDay = 0;
  const pickerForMoment = props.picker === "date" ? "day" : props.picker;
  // TODO
  const startPeriod: number =
    allDates && database
      ? sortedDatabase(database)[0].date
      : date.clone().startOf(pickerForMoment).valueOf();
  let endPeriod: number = allDates
    ? moment().valueOf()
    : date.clone().endOf(pickerForMoment).valueOf();
  endPeriod =
    endPeriod > moment().clone().endOf(pickerForMoment).valueOf()
      ? moment().clone().endOf(pickerForMoment).valueOf()
      : endPeriod;

  const miliseconds: Duration = moment.duration(
    endPeriod - startPeriod,
    "milliseconds"
  );
  const countDate = {
    date: Math.round(miliseconds.asDays()),
    week: Math.round(miliseconds.asWeeks()),
    month: Math.round(miliseconds.asMonths()),
    year: Math.round(miliseconds.asYears()),
  };

  let dateResults: {
    [key: string]: { [key: string]: { [key: string]: number } };
  } = {
    date: {},
    week: {},
    month: {},
    year: {},
  };

  const results: { [key: number]: number } = {};
  const frResults: { [key: number]: number } = {};

  const mins: { [key: number]: number } = {};
  const maxs: { [key: number]: number } = {};

  const dateMin: {
    [key: string]: { [key: string]: number };
  } = {
    date: {},
    week: {},
    month: {},
    year: {},
  };

  const dateMax: {
    [key: string]: { [key: string]: number };
  } = {
    date: {},
    week: {},
    month: {},
    year: {},
  };

  let dateCount: {
    [key: string]: { [key: string]: number };
  } = {
    date: {},
    week: {},
    month: {},
    year: {},
  };

  const ExerciseArray: ExerciseName[] = getExerciseArray();
  for (let i = 0; i < ExerciseArray.length; i++) {
    const id: number = Exercises[ExerciseArray[i]].id;
    results[id] = 0;
    frResults[id] = 0;
    mins[id] = 0;
    maxs[id] = 0;
  }

  if (props.database) {
    filteredDatabase = props.database.filter(
      (record: DatabaseRecord) =>
        record.date >= startPeriod && record.date <= endPeriod
    );
    dateResults = {
      date: {},
      week: {},
      month: {},
      year: {},
    };

    filteredDatabase.forEach((record: DatabaseRecord) => {
      const { date, type } = record;
      const { count, realCount } = record;

      //#region all time values
      if (haveToNormalizeTypeNumber(type)) {
        results[type] += realCount;
        mins[type] =
          mins[type] === undefined
            ? realCount
            : realCount < mins[type]
            ? realCount
            : mins[type];
        maxs[type] =
          maxs[type] === undefined
            ? realCount
            : realCount > maxs[type]
            ? realCount
            : maxs[type];
      } else {
        results[type] += count;
        mins[type] =
          mins[type] === undefined
            ? count
            : count < mins[type]
            ? count
            : mins[type];
        maxs[type] =
          maxs[type] === undefined
            ? count
            : count > maxs[type]
            ? count
            : maxs[type];
      }
      countChosenDay += count;
      //#endregion

      Object.keys(dateResults).forEach((dateString) => {
        const startOfDateKey = moment(date)
          .clone()
          .startOf(
            (dateString === "date" ? "day" : dateString) as unitOfTime.StartOf
          )
          .valueOf();
        dateResults[dateString][startOfDateKey] =
          dateResults[dateString][startOfDateKey] === undefined
            ? { finished: count }
            : {
                ...dateResults[dateString][startOfDateKey],
                finished:
                  dateResults[dateString][startOfDateKey].finished === undefined
                    ? count
                    : dateResults[dateString][startOfDateKey].finished + count,
              };
        dateResults[dateString][startOfDateKey] = {
          ...dateResults[dateString][startOfDateKey],
          [type]:
            dateResults[dateString][startOfDateKey][type] === undefined
              ? haveToNormalizeTypeNumber(type)
                ? realCount
                : count
              : dateResults[dateString][startOfDateKey][type] +
                (haveToNormalizeTypeNumber(type) ? realCount : count),
        };
      });
    });
    dateCount = {
      date: {},
      week: {},
      month: {},
      year: {},
    };
    Object.keys(dateResults).forEach((dateString: string) => {
      Object.keys(dateResults[dateString]).forEach((dayKey: string) => {
        Object.keys(dateResults[dateString][dayKey]).forEach((key: string) => {
          const count = dateResults[dateString][dayKey][key];
          dateCount[dateString][key] =
            dateMin[dateString][key] === undefined
              ? count
              : dateCount[dateString][key] + count;
          dateMin[dateString][key] =
            dateMin[dateString][key] === undefined
              ? count
              : count < dateMin[dateString][key]
              ? count
              : dateMin[dateString][key];
          dateMax[dateString][key] =
            dateMax[dateString][key] === undefined
              ? count
              : count > dateMax[dateString][key]
              ? count
              : dateMax[dateString][key];
        });
      });
    });
  }
  const friendData =
    props.friendId &&
    props.friends &&
    props.friends.filter((val: Friend) => val.id === props.friendId)[0]
      ?.database;
  if (friendData) {
    frFilteredDatabase = friendData.filter(
      (record: DatabaseRecord) =>
        record.date >= startPeriod && record.date <= endPeriod
    );
    frFilteredDatabase.forEach((record) => {
      frCountChosenDay += record.count;
      if (haveToNormalizeTypeNumber(record.type)) {
        frResults[record.type] += record.realCount;
      } else {
        frResults[record.type] += record.count;
      }
    });
  }

  const goal = getGoal(props.picker, props.settings.goal, date);
  const toDo = goal - countChosenDay;
  const frToDo = goal - frCountChosenDay;

  const summaryData: SummaryData[] = [
    {
      type: "Finished",
      count: countChosenDay,
      avg: Math.round(countChosenDay / countDate.date).toString(),
      min:
        Object.keys(dateResults["date"]).length < countDate.date
          ? `(${dateMin["date"].finished})`
          : dateMin["date"]?.finished?.toString(),
      max: dateMax["date"]?.finished?.toString(),
      friend: props.friendId ? frCountChosenDay : countChosenDay,
      // zeros: countDate['date'] - Object.keys(dateResults['date']).length,
    },
    {
      type: "Goal",
      count: goal,
      avg: "",
      min: "",
      max: "",
      friend: props.friendId ? goal : goal,
    },
    {
      type: "ToDo",
      count: toDo,
      avg: Math.round((toDo < 0 ? 0 : toDo) / countDate.date).toString(),
      min: "",
      max: "",
      friend: props.friendId ? frToDo : toDo,
    },
  ];

  const summaryDataForDate: { [key: string]: SummaryData[] } = {
    date: [],
    week: [],
    month: [],
    year: [],
  };
  getExerciseArray().forEach((exercise: ExerciseName) => {
    const exerciseId: number = getExerciseId(exercise);
    if (+results[exerciseId] > 0 || (props.friendId && frResults[exerciseId])) {
      summaryData.push({
        type: exercise,
        count: Math.round(results[exerciseId]),
        avg: Math.round(results[exerciseId] / countDate.date).toString(),
        min: mins[exerciseId] ? mins[exerciseId].toString() : "",
        max: maxs[exerciseId] ? maxs[exerciseId].toString() : "",
        friend: props.friendId ? Math.round(frResults[exerciseId]) : 0,
      });
    }
    if (props.extended) {
      Object.keys(dateResults).forEach((dateString: string) => {
        if (dateCount[dateString][exerciseId]) {
          summaryDataForDate[dateString].push({
            type: exercise,
            count: dateCount[dateString][exerciseId]
              ? dateCount[dateString][exerciseId]
              : 0,
            avg: dateCount[dateString][exerciseId]
              ? Math.round(
                  dateCount[dateString][exerciseId] /
                    (countDate[dateString as PickerType] || 1)
                ).toString()
              : "",
            min: dateMin[dateString][exerciseId]
              ? dateMin[dateString][exerciseId].toString()
              : "",
            max: dateMax[dateString][exerciseId]
              ? dateMax[dateString][exerciseId].toString()
              : "",
            // zeros:
            //     countDate[dateString as PickerType] -
            //     Object.keys(dateResults[dateString]).length,
            friend: 0,
          });
        }
      });
    }
  });

  const defaultTable = (
    <Table
      size={"small"}
      rowKey={(record: SummaryData) => record.type}
      loading={!props.database}
      dataSource={summaryData}
      rowClassName={(_record: SummaryData, index: number) =>
        index < 3 ? "day-background" : "ant-table-cell"
      }
      columns={
        props.friendId
          ? SUMMARY_COLUMNS_FRIEND
          : props.extended
          ? SUMMARY_COLUMNS_EXTENDED
          : SUMMARY_COLUMNS
      }
      pagination={false}
    />
  );

  return (
    <>
      {props.extended ? (
        getCoveredInCard(
          <>
            <Row justify="center" align="middle" style={{ width: "100%" }}>
              <Tabs
                defaultActiveKey="-1"
                size={"small"}
                style={{ width: "100%" }}
              >
                <Tabs.TabPane tab={"Default"} key="-1">
                  {defaultTable}
                </Tabs.TabPane>
                {Object.keys(dateResults)
                  .filter(
                    (dateString: string) =>
                      countDate[dateString as PickerType] > 0
                  )
                  .map((dateString: string, index: number) => (
                    <Tabs.TabPane
                      tab={dateString === "date" ? "day" : dateString}
                      key={index.toString()}
                    >
                      <Table
                        size={"small"}
                        rowKey={(record: SummaryData) => record.type}
                        loading={!props.database}
                        dataSource={summaryDataForDate[dateString]}
                        columns={SUMMARY_COLUMNS_EXTENDED}
                        pagination={false}
                      />
                    </Tabs.TabPane>
                  ))}
              </Tabs>
            </Row>
          </>
        )
      ) : (
        <>{defaultTable}</>
      )}
    </>
  );
}

const mapStateToProps = (
  state: IDefaultState,
  ownProps: IOwnProps
): IProps => ({
  ...state,
  ...ownProps,
});

export default connect(mapStateToProps, null)(Summary);
