import React from "react";

import { get2diffColumnsRow } from "../../../utils/row";
import ExerciseSelect from "./ExerciseSelect";

function ExerciseRow() {
  const title = "Exercise";
  const inputComponent = <ExerciseSelect />;
  return <>{get2diffColumnsRow(title, inputComponent)}</>;
}

export default ExerciseRow;
