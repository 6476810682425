import { DataSnapshot, getDatabase, onValue, ref } from "firebase/database";
import React from "react";

import LoadingComponent from "../Components/LoadingComponent";
import { DEFAULT_SETTINGS } from "../utils/const";
import { getFriendData, updateSettings } from "../utils/firebaseDb";
import {
  DatabaseRecord,
  Friend,
  IDefaultProps,
  Settings,
  User,
} from "../utils/types";
import { errorMessage } from "../utils/message";

export const withDatabase = (
  WrappedComponent: React.ComponentType<IDefaultProps>
) => {
  return class WithDatabase extends React.Component<
    IDefaultProps,
    IDefaultProps
  > {
    componentDidMount() {
      const { authUser } = this.props;
      if (authUser) {
        if (!this.props.settings) {
          onValue(
            ref(getDatabase(), `${authUser.uid}/settings/`),
            (snapshot: DataSnapshot) => {
              const data = snapshot.val();
              if (data === null || data.length === 0) {
                const personalizedDefaultSettings: Settings = {
                  ...DEFAULT_SETTINGS,
                  nick: authUser.uid,
                };
                updateSettings(personalizedDefaultSettings, () =>
                  this.props.updateSettings(personalizedDefaultSettings)
                );
              } else {
                this.props.updateSettings({
                  ...this.props.settings,
                  ...snapshot.val(),
                });
              }
            }
          );
        }
        if (!this.props.database) {
          onValue(
            ref(getDatabase(), `${authUser.uid}/records/`),
            (snapshot: DataSnapshot) => {
              let data = snapshot.val();
              if (data === null || data.length === 0) {
                this.props.updateDatabase([] as DatabaseRecord[]);
              } else {
                data = Object.keys(data).map((key: string) => data[key]);
                this.props.updateDatabase(data as DatabaseRecord[]);
              }
            }
          );
        }
        if (!this.props.friends) {
          onValue(
            ref(getDatabase(), `${authUser.uid}/friends/`),
            (snapshot: DataSnapshot) => {
              let data = snapshot.val();
              if (data === null || data.length === 0) {
                this.props.updateFriends([] as Friend[]);
              } else {
                data = Object.keys(data).map((key: string) => ({
                  id: key,
                  nick: data[key],
                }));
                data.forEach((friend: Friend) => {
                  getFriendData(friend, (friend: Friend) => {
                    this.props.addFriend(friend);
                  });
                });
              }
            }
          );
        }
        if (!this.props.users) {
          onValue(ref(getDatabase(), `users`), (snapshot: DataSnapshot) => {
            let data = snapshot.val();
            if (data === null || data.length === 0) {
              this.props.updateUsers([] as User[]);
            } else {
              data = Object.keys(data).map((key: string) => {
                if (authUser.uid === key) {
                  this.props.updateSettings({
                    ...this.props.settings,
                    nick: data[key],
                  });
                }
                return {
                  id: key,
                  nick: data[key],
                };
              });
              this.props.updateUsers(data as User[]);
            }
          });
        }
      } else {
        errorMessage('noAuthUser');
      }
    }

    render() {
      if (!this.props.authUser || !this.props.database) {
        return <LoadingComponent />;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
};
